import clsx from "clsx"
import { parse, subYears } from "date-fns"
import { useCallback, useEffect, useState } from "react"
import DatePicker from "../../../../components/DatePicker/DatePicker"
import { validateDob } from "../../../../lib/clients"
import { DATE_FORMAT } from "../../../../lib/date"
import { Client, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import retirementIncomeImage from "../../../rmjourney/portfolioComfort/RMJInvestmentGoalModal/assets/retirement-income.svg"
import selectedImage from "../../../rmjourney/portfolioComfort/RMJInvestmentGoalModal/assets/selected.svg"
import wealthAccumulationImage from "../../../rmjourney/portfolioComfort/RMJInvestmentGoalModal/assets/wealth-accumulation.svg"

type ClientDOB = {
  _id: string
  dob: Date | null
  dobRaw?: string
  name: string
}

const InvestmentGoalChooser = ({
  client,
  household,
  goalType,
  cDobs,
  onGoalChange,
  onDobChange,
  onDobRawChange,
  shouldValidate
}: {
  client?: Client
  household?: Household
  goalType?: InvestmentGoal | null
  cDobs: ClientDOB[] | null
  onGoalChange: (goalType: InvestmentGoal) => void
  onDobChange: (clientId: string, dob: Date | null) => void
  onDobRawChange: (clientId: string, dob?: string) => void
  shouldValidate?: boolean
}) => {
  const clientOrHousehold = client ?? household
  const [errors, setErrors] = useState<(string | undefined)[]>()

  const validate = useCallback(() => {
    if (goalType === "retirementIncome") {
      return cDobs?.map((c) => {
        if (!c.dob && !c.dobRaw) {
          return "Please select a date"
        } else {
          const parsed = c.dobRaw ? parse(c.dobRaw, DATE_FORMAT, new Date()) : c.dob
          return validateDob(parsed!)
        }
      })
    }
  }, [cDobs, goalType])

  useEffect(() => {
    if (shouldValidate) {
      setErrors(validate())
    }
  }, [shouldValidate, validate])

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 gap-4">
        <button
          className={clsx("relative flex items-center flex-col border rounded-1 shadow-xs p-6", {
            "bg-interactive-100 border-interactive-500": goalType === "retirementIncome" || goalType === "retirementDrawdown",
            "border-interactive-200 hover:bg-interactive-100": goalType !== "retirementIncome"
          })}
          onClick={() => onGoalChange(clientOrHousehold?.alreadyRetired ? "retirementDrawdown" : "retirementIncome")}
        >
          <img alt="" className="mb-2" src={retirementIncomeImage} aria-hidden />
          <span className="text-sec text-interactive-500 font-semibold">Retirement income</span>
          {(goalType === "retirementIncome" || goalType === "retirementDrawdown") && (
            <img alt="" className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/3" src={selectedImage} aria-hidden />
          )}
        </button>
        <button
          className={clsx("relative flex items-center flex-col border rounded-1 shadow-xs p-6", {
            "bg-interactive-100 border-interactive-500": goalType === "wealthAccumulation",
            "border-interactive-200 hover:bg-interactive-100": goalType !== "wealthAccumulation"
          })}
          onClick={() => onGoalChange("wealthAccumulation")}
        >
          <img alt="" className="mb-2" src={wealthAccumulationImage} aria-hidden />
          <span className="text-sec text-interactive-500 font-semibold">Wealth accumulation</span>
          {goalType === "wealthAccumulation" && <img alt="" className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/3" src={selectedImage} aria-hidden />}
        </button>
      </div>
      {goalType === "retirementIncome" &&
        cDobs?.map((c, i) => (
          <div className="mt-4 w-full" key={c._id}>
            <DatePicker
              id="dob"
              label={`${c.name}'s date of birth`}
              value={c.dob || null}
              onChange={(value) => {
                onDobChange(c._id, value)
              }}
              onChangeRaw={(value) => {
                onDobRawChange(c._id, value)
              }}
              yearsInAdvance={0}
              yearsInPast={125}
              defaultSelectedDate={subYears(new Date(), 40)}
              error={(shouldValidate && errors && errors[i]) || undefined}
            />
          </div>
        ))}
    </div>
  )
}

export default InvestmentGoalChooser
