import { useParams } from "react-router"
import { useMemo } from "react"
import clsx from "clsx"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import useClient from "../../../hooks/useClient"
import LoadingError from "../../../components/ClientProfile/LoadingError/LoadingError"
import useHousehold from "../../../hooks/useHousehold"
import DecisionsTable from "./components/DecisionsTable/DecisionsTable"
import { latest } from "../../../lib/clients"

const RMJYourDecisionsPage = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client, isClientLoading, clientLoadError } = useClient(clientId)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId)

  const title = useMemo(() => {
    if (clientId) {
      const decisionConsistency = latest(client!, "risk")?.risk.results?.R.cceiScore

      if (decisionConsistency === undefined || decisionConsistency === null) {
        return "-"
      } else {
        if (decisionConsistency >= 75) {
          return "You have a good understanding of risk and reward"
        } else {
          return "Here are the decisions you made in the activity"
        }
      }
    } else {
      const scores = household?.members?.map(({client}) => {
        const game = latest(client, "risk")
        return game?.risk.results?.R.cceiScore
      })
      if (scores?.every(score => score !== undefined && score !== null)) {
        if (scores?.every(score => score >= 75)) {
          return "You both have a good understanding of risk and reward"
        } else {
          return "Here are the decisions you each made in the activity"
        }
      } else {
        return "-"
      }
    }
  }, [client, clientId, household?.members])

  return (
    <div className="pg-ctr-py-lg h-full flex flex-col overflow-y-auto no-scrollbar px-10">
      <p className="text-sec text-main-600 mb-1">{household ? "YOUR DECISION PATTERNS" : "YOUR DECISION PATTERN"}</p>
      <h4 className="text-h3 font-semibold text-main-600 mb-8">{title}</h4>
      {(isClientLoading || isHouseholdLoading) && (
        <div className="absolute top-0 bottom-0  left-0 right-0 flex justify-center items-center">
          <Loading />
        </div>
      )}
      <div className="grow">
        {client && <DecisionsTable clients={[client]} />}
        {household && (
          <div>
            <DecisionsTable clients={household.members.map(({ client }) => client)} />
            <div className="flex justify-center gap-4 pt-10">
              {household.members.map(({ client }, i) => {
                const colorIndex = i % household.members.length
                return (
                  <p className="flex items-center gap-2 text-sm" key={client._id}>
                    <span
                      className={clsx("w-2 h-2 block rounded-full", {
                        "bg-avatar-0-500": colorIndex === 0,
                        "bg-avatar-1-500": colorIndex === 1
                      })}
                    />
                    {client.firstName}
                  </p>
                )
              })}
            </div>
          </div>
        )}
        {clientLoadError && <LoadingError message={"Could not load client"} />}
        {householdLoadError && <LoadingError message={"Could not load household"} />}
      </div>
    </div>
  )
}

export default RMJYourDecisionsPage
