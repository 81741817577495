import { AnimatePresence } from "framer-motion"
import { useContext, useRef, useState } from "react"
import { useMutation } from "react-query"
import { investmentMeetingDone } from "../../../../../../api/advisors"
import Loading from "../../../../../../components/ClientProfile/Loading/Loading"
import Dropdown from "../../../../../../components/Dropdown/Dropdown"
import { ClientHouseholdCacheContext } from "../../../../../../contexts/ClientHouseholdCacheContext"
import { Client, Game } from "../../../../../../models/Client"
import { Household } from "../../../../../../models/Household"
import chevronDown from "../../../../../../pages/advisor/assets/images/chevron-down.svg"
import ResultsReviewedConfirmationModal from "../../../../../advisor/components/ResultsReviewedConfirmationModal/ResultsReviewedConfirmationModal"

const ResultsAvailableMenu = ({ client, household, game, icon }: { client?: Client; household?: Household; game: Game; icon?: string }) => {
  const { replace } = useContext(ClientHouseholdCacheContext)
  const [showResultsReviewConfirmationModal, setShowResultsReviewConfirmationModal] = useState(false)
  const [showResultsAvailableMenu, setShowResultsAvailableMenu] = useState<boolean>(false)
  const resultsAvailableMenuBtn = useRef<HTMLButtonElement>(null)
  const { mutate: markAsReviewed, status: updateState } = useMutation({
    mutationFn: () =>
      investmentMeetingDone((client ?? household)!.advisorId!, client ? "client" : "household", household ? household._id : client!._id, {
        markAsReviewed: true,
        gameId: game.experimentGroupId
      }),
    onSuccess: (updated) => {
      replace(updated)
      setShowResultsAvailableMenu(false)
    },
    onError: (err) => {
      console.error("Error marking the activity as reviewed", err)
    }
  })

  return (
    <>
      <div className="flex justify-center relative">
        <button
          aria-label="results available menu options"
          className="btn-text flex-shrink-0 p-1 mt-1 mr-1 hover:bg-interactive-200"
          onClick={() => setShowResultsAvailableMenu(!showResultsAvailableMenu)}
          ref={resultsAvailableMenuBtn}
        >
          <img src={icon ?? chevronDown} className="flex-shrink-0" alt="" aria-hidden />
        </button>
        {showResultsAvailableMenu && (
          <Dropdown className="m-4" trigger={resultsAvailableMenuBtn} overlayClassName="w-64" handleClose={() => setShowResultsAvailableMenu(false)}>
            <div className="my-2">
              <ul className="list-none">
                <li className="w-full h-12">
                  {updateState === "loading" ? (
                    <Loading />
                  ) : updateState === "error" ? (
                    <span className="text-error mx-2">Error marking the activity as reviewed</span>
                  ) : (
                    <button
                      className="btn text-p py-3.5 px-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                      onClick={() => {
                        if (game?.gameType === "risk") {
                          setShowResultsAvailableMenu(false)
                          setShowResultsReviewConfirmationModal(true)
                        } else {
                          markAsReviewed()
                        }
                      }}
                    >
                      Mark as reviewed
                    </button>
                  )}
                </li>
              </ul>
            </div>
          </Dropdown>
        )}
      </div>
      <AnimatePresence>
        {showResultsReviewConfirmationModal && (
          <ResultsReviewedConfirmationModal client={client} household={household} game={game} onClose={() => setShowResultsReviewConfirmationModal(false)} />
        )}
      </AnimatePresence>
    </>
  )
}

export default ResultsAvailableMenu
