import clsx from "clsx"
import { format, subYears } from "date-fns"
import { useCallback, useContext, useEffect, useState } from "react"
import { ClientUpdateRequest } from "../../../../../../api/clients"
import { HouseholdUpdateRequest, updateHousehold } from "../../../../../../api/households"
import AvatarBadges from "../../../../../../components/AvatarBadges/AvatarBadges"
import Loading from "../../../../../../components/ClientProfile/Loading/Loading"
import DatePicker from "../../../../../../components/DatePicker/DatePicker"
import DollarIcon from "../../../../../../components/DolarIcon/DollarIcon"
import NumberInput from "../../../../../../components/NumberInput/NumberInput"
import TextInput from "../../../../../../components/TextInput/TextInput"
import { ClientHouseholdCacheContext } from "../../../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../../../contexts/FirmContext"
import { validate, validateHousehold } from "../../../../../../lib/clients"
import { Household, HouseholdMember } from "../../../../../../models/Household"
import { AuthContext } from "../../../../../../views/auth/AuthContext"
import { HouseholdErrors } from "../../../../../advisor/CreateHouseholdPage"
import { ClientUpdate } from "./EditProfileModal"
import ProviderAlert from "./ProviderAlert"

export type HouseholdUpdate = {
  name?: string
  investmentAmount?: number
  annualInvestmentContribution?: number
  retirementIncomeGoal?: number
  otherSourcesRetirementIncome?: number
  retirementAge?: number | null
  memberUpdates: ClientUpdate[]
}

const EditHouseholdProfileModal = ({ household, onClose }: { household: Household; onClose: () => void }) => {
  const { sessionInfo } = useContext(AuthContext)
  const { firm } = useContext(FirmContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const [data, setData] = useState<HouseholdUpdate>({ memberUpdates: [{}, {}] })
  const [errors, setErrors] = useState<HouseholdErrors>({ members: [{}, {}] })
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [clientUpdateState, setClientUpdateState] = useState<"updating" | "success" | "error" | undefined>()
  const [currentViewClient, setCurrentViewClient] = useState<HouseholdMember>()

  const handleSaveChanges = () => {
    setShouldValidate(true)
    const errs = validateHouseholdForm()
    if (
      errs &&
      (errs.name ||
        errs.investmentAmount ||
        errs.annualInvestmentContribution ||
        errs.retirementIncomeGoal ||
        errs.otherSourcesRetirementIncome ||
        errs.retirementAge)
    ) {
      setCurrentViewClient(undefined)
    } else if (errs && errs.members.length > 0 && Object.keys(errs.members[0]).length > 0) {
      setCurrentViewClient(household.members[0])
    } else if (errs && errs.members.length > 0 && Object.keys(errs.members[1]).length > 0) {
      setCurrentViewClient(household.members[1])
    } else {
      setClientUpdateState("updating")
      console.log("held member update requests:", data.memberUpdates)
      const memberUpdateRequests = data.memberUpdates.map((memberUpdate) => {
        return {
          ...memberUpdate,
          ...(memberUpdate.dob !== undefined ? { dob: memberUpdate.dob !== null ? format(memberUpdate.dob, "yyyy-MM-dd") : null } : {})
        } as ClientUpdateRequest
      })
      console.log("update requests: ", { memberUpdateRequests, householdUpdate: { ...data, memberUpdates: undefined } })
      updateHousehold(sessionInfo!, household, memberUpdateRequests, { ...data, memberUpdates: undefined } as HouseholdUpdateRequest)
        .then((updatedHousehold) => {
          console.log("updated household", updatedHousehold)
          setClientUpdateState("success")
          replace(updatedHousehold)
          onClose()
        })
        .catch((error) => {
          console.error("error updating household details", error)
          setClientUpdateState("error")
        })
    }
  }

  const validateForm = useCallback(
    (clientUpdateData: ClientUpdate, householdMember: HouseholdMember) => {
      const { client } = householdMember
      const dob = clientUpdateData.dob ?? client.dob
      return validate(
        {
          firstName: clientUpdateData.firstName ?? client.firstName,
          lastName: clientUpdateData.lastName ?? client.lastName,
          email: clientUpdateData.email ?? client.email,
          dob: dob ? new Date(dob) : undefined,
          dobRaw: clientUpdateData.dobRaw,
          investmentAmount: clientUpdateData.investmentAmount ?? client.investmentAmount,
          currentAnnualIncome: clientUpdateData.currentAnnualIncome ?? client.currentAnnualIncome,
          retirementIncomeGoal: clientUpdateData.retirementIncomeGoal ?? client.retirementIncomeGoal,
          annualInvestmentContribution: clientUpdateData.annualInvestmentContribution ?? client.annualInvestmentContribution,
          otherSourcesRetirementIncome: clientUpdateData.otherSourcesRetirementIncome ?? client.otherSourcesRetirementIncome
        },
        firm!,
        client.advisorId
      )
    },
    [firm]
  )

  const validateHouseholdForm = useCallback(() => {
    const householdErrs: HouseholdErrors = validateHousehold(
      {
        name: data.name ?? household.name,
        investmentAmount: data.investmentAmount ?? household.investmentAmount,
        annualInvestmentContribution: data.annualInvestmentContribution ?? household.annualInvestmentContribution,
        retirementIncomeGoal: data.retirementIncomeGoal ?? household.retirementIncomeGoal,
        otherSourcesRetirementIncome: data.otherSourcesRetirementIncome ?? household.otherSourcesRetirementIncome,
        retirementAge: data.retirementAge ?? household.retirementAge,
        members: []
      },
      firm!,
      household.advisorId
    )
    data.memberUpdates?.forEach((member, i) => {
      const memberErrors = validateForm(member, household.members[i])
      if (Object.keys(memberErrors).length > 0) {
        householdErrs.members[i] = memberErrors
      }
    })
    return householdErrs
  }, [validateForm, data, firm, household])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateHouseholdForm()
      setErrors(nextErrors)
    }
  }, [data, shouldValidate, validateForm, validateHouseholdForm])

  return (
    <div className="w-[600px]">
      <div className="flex flex-col w-full bg-white pr-2">
        <div className="modal-header flex flex-row items-center justify-items-start pb-4">
          <AvatarBadges clients={household.members.map(({ client }) => client)} />
          <h2 className="text-h2 text-main-600 font-semibold ml-3">{`Edit household profile`}</h2>
        </div>
        <div className="text-sec flex flex-row gap-x-4">
          <button
            className={clsx("relative cursor-pointer font-bold border-b-2 outline-none focus:shadow-focus px-2 py-2", {
              "text-interactive-600 border-b-interactive-600": !currentViewClient,
              "text-interactive-400 border-transparent": currentViewClient
            })}
            onClick={() => {
              setCurrentViewClient(undefined)
            }}
          >
            Household
          </button>
          {household.members.map((member) => (
            <button
              className={clsx("relative cursor-pointer font-bold border-b-2 outline-none focus:shadow-focus px-2 py-2", {
                "text-interactive-600 border-b-interactive-600": currentViewClient?.id === member.id,
                "text-interactive-400 border-transparent": currentViewClient?.id !== member.id
              })}
              onClick={() => {
                setCurrentViewClient(member)
              }}
              key={member.id}
            >
              {member.client.firstName}
            </button>
          ))}
        </div>
        <hr className="mb-4 text-surface-300" />
        <ProviderAlert household={household} />

        <div className="modal-body overflow-y-auto pb-8" style={{ height: "440px", maxHeight: "calc(100vh - 300px)" }}>
          {data.memberUpdates.map((memberUpdate, i) => {
            return (
              <div
                className={clsx("grid grid-cols-1 gap-5 text-sec text-main-600", household.members[i].id === currentViewClient?.id ? "grid" : "hidden")}
                key={i}
              >
                <TextInput
                  label="First name"
                  name="firstName"
                  onChange={(val) =>
                    setData((old) => ({
                      ...old,
                      memberUpdates: old.memberUpdates?.map((mem, idx) => {
                        return i === idx ? { ...mem, firstName: val } : mem
                      })
                    }))
                  }
                  value={memberUpdate.firstName ?? household.members[i].client.firstName}
                  error={errors.members[i].firstName}
                  isDisabled={!!household.externalId}
                />
                <TextInput
                  label="Last name"
                  name="lastName"
                  onChange={(val) =>
                    setData((old) => ({
                      ...old,
                      memberUpdates: old.memberUpdates?.map((mem, idx) => {
                        return i === idx ? { ...mem, lastName: val } : mem
                      })
                    }))
                  }
                  value={memberUpdate.lastName ?? household.members[i].client.lastName}
                  error={errors.members[i].lastName}
                  isDisabled={!!household.externalId}
                />
                <TextInput
                  label="Email address"
                  name="email"
                  onChange={(val) =>
                    setData((old) => ({ ...old, memberUpdates: old.memberUpdates?.map((mem, idx) => (i === idx ? { ...mem, email: val } : mem)) }))
                  }
                  value={memberUpdate.email ?? household.members[i].client.email}
                  error={errors.members[i].email}
                  isDisabled={!!household.externalId}
                />

                <DatePicker
                  id="dob"
                  label="Date of birth"
                  value={(memberUpdate.dob ?? (household.members[i].client.dob ? new Date(household.members[i].client.dob) : "")) || null}
                  onChange={(value: Date | null) => {
                    setData((old) => ({
                      ...old,
                      memberUpdates: old.memberUpdates?.map((mem, idx) => {
                        return i === idx
                          ? {
                              ...mem,
                              dob: value
                                ? !household.members[i].client.dob || value.getTime() !== new Date(household.members[i].client.dob).getTime()
                                  ? value // it wasn't set or the value differs
                                  : undefined // it is set but value is unchanged
                                : household.members[i].client.dob
                                ? null // it was set before but has been erased => delete
                                : undefined // it isn't set but is also wasn;t set before
                            }
                          : mem
                      })
                    }))
                  }}
                  onChangeRaw={(value: string) => {
                    console.log("dobRaw", value)
                    setData((old) => ({
                      ...old,
                      memberUpdates: old.memberUpdates?.map((mem, idx) => {
                        return i === idx ? { ...mem, dobRaw: value } : mem
                      })
                    }))
                  }}
                  yearsInAdvance={0}
                  yearsInPast={125}
                  defaultSelectedDate={subYears(new Date(), 40)}
                  error={errors.members[i].dob}
                  isDisabled={!!household.externalId}
                />
              </div>
            )
          })}

          <div className={clsx("grid grid-cols-1 gap-5 text-sec text-main-600", !currentViewClient?.id ? "grid" : "hidden")}>
            
            <TextInput
              label="Household name"
              name="name"
              onChange={(val) => setData((prev) => ({ ...prev, name: val }))}
              value={data.name ?? household.name}
              error={errors.name}
            />

            <NumberInput
              name="investmentAmount"
              value={data.investmentAmount ?? household.investmentAmount}
              label="Investment amount"
              onChange={(val) => setData((prev) => ({ ...prev, investmentAmount: val || 0 }))}
              prefix={
                <div className="pl-3">
                  <DollarIcon />
                </div>
              }
              error={errors.investmentAmount}
              mandatory={false}
            />
            {firm?.uiConfig?.RETIREMENT_GOAL_ATTRIBUTES && (
              <>
                <NumberInput
                  name="annualContribution"
                  value={data.annualInvestmentContribution ?? household.annualInvestmentContribution}
                  label="Annual Contribution"
                  onChange={(val) => setData((prev) => ({ ...prev, annualInvestmentContribution: val || 0 }))}
                  prefix={
                    <div className="pl-3">
                      <DollarIcon />
                    </div>
                  }
                  error={errors.annualInvestmentContribution}
                  mandatory={false}
                />
                <NumberInput
                  name="desiredIncome"
                  value={data.retirementIncomeGoal ?? household.retirementIncomeGoal}
                  label="Desired income"
                  onChange={(val) => setData((prev) => ({ ...prev, retirementIncomeGoal: val || 0 }))}
                  prefix={
                    <div className="pl-3">
                      <DollarIcon />
                    </div>
                  }
                  error={errors.retirementIncomeGoal}
                  mandatory={false}
                />
                <NumberInput
                  name="otherSourcesRetirementIncome"
                  value={data.otherSourcesRetirementIncome ?? household.otherSourcesRetirementIncome}
                  label="Retirement income from other sources"
                  onChange={(val) => setData((prev) => ({ ...prev, otherSourcesRetirementIncome: val || 0 }))}
                  prefix={
                    <div className="pl-3">
                      <DollarIcon />
                    </div>
                  }
                  error={errors.otherSourcesRetirementIncome}
                  mandatory={false}
                />
                <TextInput
                  label="Retirement age"
                  name="retirementAge"
                  onChange={(val) => setData((prev) => ({ ...prev, retirementAge: val ? +val : null }))}
                  inputMode="numeric"
                  value={(data.retirementAge || household.retirementAge || "").toString()}
                />
              </>
            )}
          </div>
        </div>
        {clientUpdateState === "error" && <div className="text-error text-right">Error updating the client details</div>}
        <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end pt-4">
          {clientUpdateState === "updating" && <div className="h-10 mr-2"><Loading /></div>}
          <div className="mx-auto">
            <button className="btn btn-medium btn-secondary w-[10rem] mr-3 text-interactive-500" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn btn-medium w-[10rem] btn-primary"
              onClick={handleSaveChanges}
              disabled={clientUpdateState === "updating" || Object.keys(data).length === 0}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditHouseholdProfileModal
