import { AnimatePresence } from "framer-motion"
import { useContext, useState } from "react"
import Modal from "../../../components/Modal/Modal"
import ImportClients from "../components/ImportModal/ImportClients"

import { useNavigate } from "react-router"
import { FirmContext } from "../../../contexts/FirmContext"
import { IntegrationConfig } from "../../../models/Firm"
import excel from "./assets/excel.svg"
import intelliflo from "./assets/intelliflo.svg"
import ImportConfirm from "./ImportConfirm"
import ImportFromIntellifloFlow from "./ImportFromIntellifloFlow"

const ImportClientsCard = ({ icon, text, onClick }: { icon: string; text: string; onClick: () => void }) => {
  return (
    <div
      className="import-clients-card w-full lg:w-1/3 lg-fluid-max:w-1/4 flex flex-col items-start justify-stretch rounded shadow-100 cursor-pointer"
      onClick={onClick}
    >
      <div className="import-clients-card-iconarea w-full h-37 bg-surface-100 flex items-center justify-center">
        <img src={icon} />
      </div>
      <div className="import-clients-card-text p-4 font-bold text-sec w-full flex items-center gap-x-3">
        <span>{text}</span>
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.75005 9.16476C1.53671 9.16476 1.32338 9.08309 1.16088 8.92059C0.835046 8.59476 0.835046 8.06809 1.16088 7.74226L3.91504 4.98809L1.26505 2.24392C0.945879 1.91226 0.955045 1.38476 1.28588 1.06559C1.61755 0.746425 2.14505 0.755591 2.46421 1.08559L5.68254 4.41893C5.99838 4.74643 5.99421 5.26559 5.67254 5.58726L2.33921 8.92059C2.17671 9.08309 1.96338 9.16476 1.75005 9.16476Z"
            fill="#31366B"
          />
        </svg>
      </div>
    </div>
  )
}

const ImportClientsPage = () => {
  const [showImportClientsModal, setShowImportClientsModal] = useState<boolean>(false)
  const [showImportConfirmModal, setShowImportConfirmModal] = useState<boolean>(false)
  const { firm } = useContext(FirmContext)
  const [activeIntegration, setActiveIntegration] = useState<IntegrationConfig | null>()
  const navigate = useNavigate()

  return (
    <div className="import-clients-page pg-ctr py-10 w-full h-full px-10 flex flex-col items-stretch gap-y-10">
      <h2 className="text-h2">Import clients</h2>
      <div className="import-clients-cards-container flex flex-wrap gap-6">
        <ImportClientsCard
          icon={excel}
          text="Import from Excel"
          onClick={() => Object.keys(firm?.integrations ?? {}).length ? setShowImportConfirmModal(true) : setShowImportClientsModal(true)}
        />
        {firm?.integrations?.["intelliflo-au"] && (
          <ImportClientsCard
            icon={intelliflo}
            text="Import from intelliflo (AU)"
            onClick={() => setActiveIntegration(firm?.integrations?.["intelliflo-au"])}
          />
        )}
      </div>
      <AnimatePresence>
        {showImportClientsModal && (
          <Modal handleClose={() => setShowImportClientsModal(false)}>
            <ImportClients handleClose={() => setShowImportClientsModal(false)} />
          </Modal>
        )}
        {showImportConfirmModal && (
          <Modal contentClassName="max-w-screen-md" handleClose={() => setShowImportConfirmModal(false)}>
            <ImportConfirm
              handleClose={() => setShowImportConfirmModal(false)}
              handleConfirm={() => {
                setShowImportConfirmModal(false)
                setShowImportClientsModal(true)
              }}
            />
          </Modal>
        )}
        {activeIntegration && (
          <Modal handleClose={() => setActiveIntegration(null)}>
            <ImportFromIntellifloFlow integration={activeIntegration} handleClose={() => setActiveIntegration(null)} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ImportClientsPage
