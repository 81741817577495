import { differenceInCalendarYears, isValid, parse } from "date-fns"
import { Client, Game, GameType } from "../models/Client"
import { Firm } from "../models/Firm"
import { HouseholdErrors, NewHousehold } from "../pages/advisor/CreateHouseholdPage"
import { ClientErrors, NewClient } from "../pages/advisor/CreateIndividualClientPage"
import { formatCurrencyLong } from "./currency"
import { DATE_FORMAT } from "./date"
import { validateEmail } from "./email"
import { Household } from "../models/Household"

export const validate = (client: NewClient, firm: Firm, advisor?: string) => {
  const {
    firstName,
    lastName,
    email,
    dob,
    dobRaw,
    investmentAmount,
    currentAnnualIncome,
    retirementIncomeGoal,
    annualInvestmentContribution,
    otherSourcesRetirementIncome,
    retirementAge
  } = client
  const errs: ClientErrors = {}
  if (!firstName?.trim()) {
    errs.firstName = "First name cannot be empty"
  }
  if (!lastName?.trim()) {
    errs.lastName = "Last name cannot be empty"
  }
  if (email?.trim() && !validateEmail(email.trim())) {
    errs.email = "Please enter a valid email address"
  }
  const investmentAmountConfig = firm.uiConfig.investmentAmount
  if (investmentAmount && (investmentAmount < investmentAmountConfig.min || investmentAmount > investmentAmountConfig.max)) {
    errs.investmentAmount = `Please add a value between ${formatCurrencyLong(investmentAmountConfig.min)} and ${formatCurrencyLong(investmentAmountConfig.max)}`
  }
  if (currentAnnualIncome && (currentAnnualIncome < 0 || currentAnnualIncome > 1000000000000)) {
    errs.currentAnnualIncome = "Please add a valid value"
  }
  if (retirementIncomeGoal && (retirementIncomeGoal < 0 || retirementIncomeGoal > 1000000000000)) {
    errs.retirementIncomeGoal = "Please add a valid value"
  }
  if (annualInvestmentContribution && (annualInvestmentContribution < 0 || annualInvestmentContribution > 1000000000000)) {
    errs.annualInvestmentContribution = "Please add a valid value"
  }
  if (otherSourcesRetirementIncome && (otherSourcesRetirementIncome < 0 || otherSourcesRetirementIncome > 1000000000000)) {
    errs.otherSourcesRetirementIncome = "Please add a valid value"
  }
  if (retirementAge && retirementAge < 40) {
    errs.retirementAge = "Retirement age is too young"
  }
  if (retirementAge && retirementAge > 120) {
    errs.retirementAge = "Retirement age is too old"
  }
  const parsed = dobRaw ? parse(dobRaw, DATE_FORMAT, new Date()) : !dobRaw && dob ? new Date(dob) : null
  if (parsed) {
    const dobError = validateDob(parsed)
    if (dobError) {
      errs.dob = dobError
    }
  }
  if (!advisor) {
    errs.advisor = "Please select an adviser"
  }
  return errs
}

export const validateHousehold = (household: NewHousehold, firm: Firm, advisor?: string) => {
  const { name, investmentAmount, annualInvestmentContribution, retirementIncomeGoal, otherSourcesRetirementIncome, retirementAge } = household
  const errs: HouseholdErrors = { members: [{}, {}] }
  if (!name?.trim()) {
    errs.name = "Name cannot be empty"
  }
  const investmentAmountConfig = firm.uiConfig.investmentAmount
  if (investmentAmount && (investmentAmount < investmentAmountConfig.min || investmentAmount > investmentAmountConfig.max)) {
    errs.investmentAmount = `Please add a value between ${formatCurrencyLong(investmentAmountConfig.min)} and ${formatCurrencyLong(investmentAmountConfig.max)}`
  }
  if (annualInvestmentContribution && (annualInvestmentContribution < 0 || annualInvestmentContribution > 1000000000000)) {
    errs.annualInvestmentContribution = "Please add a valid value"
  }
  if (retirementIncomeGoal && (retirementIncomeGoal < 0 || retirementIncomeGoal > 1000000000000)) {
    errs.retirementIncomeGoal = "Please add a valid value"
  }
  if (otherSourcesRetirementIncome && (otherSourcesRetirementIncome < 0 || otherSourcesRetirementIncome > 1000000000000)) {
    errs.otherSourcesRetirementIncome = "Please add a valid value"
  }
  if (retirementAge && retirementAge < 40) {
    errs.retirementAge = "Retirement age is too young"
  }
  if (retirementAge && retirementAge > 120) {
    errs.retirementAge = "Retirement age is too old"
  }
  if (!advisor) {
    errs.advisor = "Please select an adviser"
  }
  console.log("household errrs", household, errs)
  return errs
}

export const validateDob = (dob: Date): string | undefined => {
  if (!isValid(dob)) {
    return `Please enter date in ${DATE_FORMAT.toLowerCase()} format`
  } else {
    const age = differenceInCalendarYears(new Date(), dob)
    if (Number.isNaN(age) || age < 16 || age > 125) {
      return "Please add an age between 16 and 125"
    }
  }
}

export const areDecisionsIncoherent = (game?: Game): boolean => {
  return game?.risk?.results?.R.cceiScore ? game.risk.results.R.cceiScore < 75 : false
}

export const gameUrlByType = (client: Client, game: Game, firm: Firm) => {
  return `${firm.gameBaseUrls![game.gameType ?? "risk"]}?testToken=${game.token}&name=${encodeURIComponent(client.firstName)}`
}

export const latest = (clientOrHousehold: Client | Household, gameType: GameType) => {
  return clientOrHousehold?.games
    ?.sort((game1, game2) => game2.experimentGroupId!.localeCompare(game1.experimentGroupId!))
    .find((game) => game.gameType === gameType)
}

export const supportedGames = (client: Client | Household, firm?: Firm) => client.gameTypes ?? firm?.config?.supportedGames?.map((game) => game.type) ?? ["risk"]

export const latestGamesByType = (client: Client, firm?: Firm) => {
  return supportedGames(client, firm).reduce((acc, game) => {
    acc[game] = latest(client, game)
    return acc
  }, {} as Record<GameType, Game | undefined>)
}

export const efpUrl = (client: Client) => {
  return `${window.location.protocol}//${window.location.host}/client/efp/${client.wealthClientId}?name=${encodeURIComponent(client.firstName)}`
}

export const comfortMatch = (game: Game) => {
  const comfortMatches = [...(game.portfolioMappings || [])].sort((a, b) => {
    const d = b.riskComfort! - a.riskComfort!
    return d ? d : (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0)
  })
  return comfortMatches[0]
}

const frequencyMultiplierToAnnual = {
  weekly: 52,
  fortnightly: 26,
  quarterly: 36,
  monthly: 12,
  annually: 1
}
export const finances = (client: Client) => {
  const assets = (client?.pfs?.assets || []).reduce((acc, asset) => acc + (asset.amount || 0), 0)
  const liabilities = (client?.pfs?.liabilities || []).reduce((acc, liability) => acc + (liability.amount || 0), 0)
  const netWorth = assets - liabilities
  const baseFrequency = client?.pfs?.income?.find((source) => source.type === "salary")?.frequency || "fortnightly"
  const totalIncome = (client?.pfs?.income || []).reduce((acc, incomeSource) => {
    return acc + (incomeSource.amount || 0) * frequencyMultiplierToAnnual[incomeSource.frequency ?? "monthly"]
  }, 0)
  const totalExpenses = (client?.pfs?.expenses || []).reduce((acc, expense) => {
    return acc + (expense.amount || 0) * frequencyMultiplierToAnnual[expense.frequency ?? "monthly"]
  }, 0)
  const balance = totalIncome - totalExpenses
  const balanceLabel = balance >= 0 ? "Surplus" : "Deficit"
  return {
    assets,
    liabilities,
    netWorth,
    expenses: totalExpenses / frequencyMultiplierToAnnual[baseFrequency],
    balance: balance / frequencyMultiplierToAnnual[baseFrequency],
    balanceLabel,
    baseFrequency
  }
}
