// import { Trans } from "@lingui/macro"
import { AnimatePresence, motion } from "framer-motion"
import { useContext, useState } from "react"
import AvatarBadges from "../../../../components/AvatarBadges/AvatarBadges"
import Modal from "../../../../components/Modal/Modal"
import SidebarMenu from "../../../../components/SidebarMenu/SidebarMenu"
import SidebarMenuItem from "../../../../components/SidebarMenuItem/SidebarMenuItem"
import SidebarSection from "../../../../components/SidebarSection/SidebarSection"
import SidebarSectionRow from "../../../../components/SidebarSectionRow/SidebarSectionRow"
import { FirmContext } from "../../../../contexts/FirmContext"
import { formatCurrencyLong } from "../../../../lib/currency"
import { getAge } from "../../../../lib/date"
import { Client } from "../../../../models/Client"
import EditProfileModal from "./components/EditProfileModal/EditProfileModal"
import MergeClientsModal from "./components/MergeClientsModal/MergeClientsModal"
import { useTheme } from "../../../../contexts/ThemeContext"
import { t } from "@lingui/macro"
import { clsx } from "clsx"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import NeedsResultsModal from "./components/NeedsResults/NeedsResultsModal"
import { latest } from "../../../../lib/clients"

const ProfileOverview = ({ client, isExpanded = true, isAnimating }: { client: Client; isExpanded: boolean; isAnimating: boolean }) => {
  const { firm } = useContext(FirmContext)
  const {
    annualInvestmentContribution,
    dob,
    email,
    firstName,
    lastName,
    currentAnnualIncome,
    investmentAmount,
    otherSourcesRetirementIncome,
    retirementAge,
    retirementIncomeGoal
  } = client
  const [isEditModal, setIsEditModal] = useState(false)
  const [showNeedsResultsModal, setShowNeedsResultsModal] = useState(false)
  const [isMergeClientsModal, setIsMergeClientsModal] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <motion.div
      animate={{ width: "100%", transition: { duration: 0.1, ease: "easeInOut" } }}
      className="profile-overview-wrapper w-full h-full flex flex-col overflow-auto no-scrollbar"
    >
      <div
        className={clsx("profile-overview-header sticky top-0 w-full flex items-center bg-interactive-600 border-b border-interactive-500 p-5", {
          "justify-center": !isExpanded
        })}
      >
        <Link
          to={`/clients/${client._id}`}
          aria-label="Go to client profile"
          className={clsx("w-full gap-x-3 flex items-center transition ease-in-out duration-500", { "translate-x-1": !isExpanded && !isAnimating })}
        >
          <AvatarBadges clients={[client]} colorByStatus={theme.colors.avatarColorByStatus} size="medium" />
          {isExpanded && (
            <h3 className="text-white whitespace-nowrap">
              {firstName} {lastName}
            </h3>
          )}
        </Link>
      </div>
      <div className="profile-overview-content grow">
        {isExpanded && (
          <SidebarSection>
            {theme.pages.profile.sidebar.personalDetails.age && <SidebarSectionRow label="Age" value={dob ? getAge(dob) : ""} />}
            {theme.pages.profile.sidebar.personalDetails.investmentAmount && (
              <SidebarSectionRow
                label={t({ id: "profile-overview-investment-amount", message: "Investment amount" })}
                value={formatCurrencyLong(investmentAmount)}
              />
            )}
            {firm?.uiConfig?.CURRENT_INCOME && (
              <SidebarSectionRow label="Current annual income (before tax)" value={formatCurrencyLong(currentAnnualIncome, "p/y")} />
            )}
            {firm?.uiConfig?.RETIREMENT_GOAL_ATTRIBUTES && (
              <>
                <SidebarSectionRow label="Retirement income goal" value={formatCurrencyLong(retirementIncomeGoal, "p/y")} />
                <SidebarSectionRow label="Annual investment contribution" value={formatCurrencyLong(annualInvestmentContribution, "p/y")} />
                <SidebarSectionRow label="Retirement income from other sources" value={formatCurrencyLong(otherSourcesRetirementIncome, "p/y")} />
                <SidebarSectionRow label="Expected retirement age" value={retirementAge} />
              </>
            )}
          </SidebarSection>
        )}
      </div>
      <SidebarMenu>
        {theme.pages.profile.sidebar.menu.portfolioAnalyser && (
          <SidebarMenuItem
            isActive={window.location.pathname.includes("/portfolio-analyser")}
            isDisabled={client.status === "Archived"}
            label={isExpanded ? "Portfolio analyser" : ""}
            leftElement={
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.351 11.7145C16.9152 11.5595 16.4402 11.787 16.2877 12.222C15.3477 14.8803 12.8202 16.6662 10.0002 16.6662C6.32437 16.6662 3.33354 13.6762 3.33354 9.99951C3.33354 7.1795 5.11937 4.652 7.7777 3.712C8.21187 3.5595 8.43937 3.08367 8.28604 2.6495C8.1327 2.21617 7.65687 1.98784 7.2227 2.142C3.89937 3.31617 1.66687 6.47367 1.66687 9.99951C1.66687 14.5945 5.4052 18.3328 10.0002 18.3328C13.526 18.3328 16.6835 16.1012 17.8585 12.777C18.0119 12.3437 17.7852 11.8678 17.351 11.7145ZM11.6666 8.33317V3.39234C14.2175 3.759 16.2408 5.7815 16.6075 8.33317H11.6666ZM10.8333 1.6665C10.3733 1.6665 9.99995 2.039 9.99995 2.49984V9.1665C9.99995 9.62734 10.3733 9.99984 10.8333 9.99984H17.5C17.96 9.99984 18.3333 9.62734 18.3333 9.1665C18.3333 5.03067 14.9683 1.6665 10.8333 1.6665Z"
                  fill="currentcolor"
                />
              </svg>
            }
            onClick={() => {
              if (latest(client, "esg")?.esg?.results) {
                navigate(`/clients/${client._id}/portfolio-analyser`)
              } else {
                setShowNeedsResultsModal(true)
              }
            }}
          />
        )}
        {theme.pages.profile.sidebar.menu.clientProfile && (
          <SidebarMenuItem
            isActive={window.location.pathname === `/clients/${client._id}`}
            isDisabled={client.status === "Archived"}
            label={isExpanded ? `${client.firstName}'s profile` : ""}
            leftElement={
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.8334 16.6667C15.8334 17.1267 15.4609 17.5 15.0001 17.5C14.5392 17.5 14.1667 17.1267 14.1667 16.6667C14.1667 14.3692 12.2976 12.5 10.0001 12.5C7.70258 12.5 5.83341 14.3692 5.83341 16.6667C5.83341 17.1267 5.46091 17.5 5.00008 17.5C4.53925 17.5 4.16675 17.1267 4.16675 16.6667C4.16675 13.45 6.78425 10.8333 10.0001 10.8333C13.2159 10.8333 15.8334 13.45 15.8334 16.6667ZM10.0001 4.16667C10.9192 4.16667 11.6667 4.91417 11.6667 5.83333C11.6667 6.7525 10.9192 7.5 10.0001 7.5C9.08091 7.5 8.33341 6.7525 8.33341 5.83333C8.33341 4.91417 9.08091 4.16667 10.0001 4.16667ZM10.0001 9.16667C11.8384 9.16667 13.3334 7.67167 13.3334 5.83333C13.3334 3.995 11.8384 2.5 10.0001 2.5C8.16175 2.5 6.66675 3.995 6.66675 5.83333C6.66675 7.67167 8.16175 9.16667 10.0001 9.16667Z"
                  fill="currentcolor"
                />
              </svg>
            }
            onClick={() => navigate(`/clients/${client._id}`)}
          />
        )}
        {theme.pages.profile.sidebar.menu.goalProjector && (
          <SidebarMenuItem
            isActive={window.location.pathname.startsWith(`/clients/${client._id}/explore`)}
            label={isExpanded ? "Goal Projector" : ""}
            leftElement={
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.6736 0.448996C14.5976 0.0218488 14.0147 -0.0491207 13.8385 0.347332L13.2992 1.56073V2.4913C13.2646 2.51327 13.2313 2.538 13.1995 2.5655C11.8701 1.41525 10.138 0.718573 8.2462 0.718573C4.06689 0.718573 0.666748 4.11871 0.666748 8.29802C0.666748 12.4773 4.06689 15.8775 8.2462 15.8775C12.4255 15.8775 15.8256 12.4773 15.8256 8.29802C15.8256 6.74113 15.3538 5.29236 14.5456 4.08722H15.5449L17.0739 3.39919C17.4666 3.22245 17.3977 2.64503 16.9744 2.56566L14.9835 2.19236L14.6736 0.448996ZM8.2462 14.3616C4.9029 14.3616 2.18264 11.6413 2.18264 8.29802C2.18264 4.95473 4.9029 2.23446 8.2462 2.23446C9.71911 2.23446 11.0711 2.76244 12.1228 3.63907L11.4067 4.35522C10.5412 3.66061 9.44225 3.24506 8.2462 3.24506C5.45552 3.24506 3.19323 5.50735 3.19323 8.29802C3.19323 11.0887 5.45552 13.351 8.2462 13.351C11.0369 13.351 13.2992 11.0887 13.2992 8.29802C13.2992 7.31231 13.0169 6.39252 12.5288 5.61506L13.2569 4.88697C13.9211 5.85944 14.3098 7.03422 14.3098 8.29802C14.3098 11.6413 11.5895 14.3616 8.2462 14.3616ZM10.1509 5.61101L8.98026 6.78165C8.75839 6.67404 8.50934 6.6137 8.2462 6.6137C7.31597 6.6137 6.56188 7.3678 6.56188 8.29802C6.56188 9.22825 7.31597 9.98235 8.2462 9.98235C9.17642 9.98235 9.93052 9.22825 9.93052 8.29802C9.93052 8.27031 9.92985 8.24275 9.92853 8.21537L11.2337 6.91018C11.43 7.33194 11.5396 7.80218 11.5396 8.29802C11.5396 10.1169 10.0651 11.5914 8.2462 11.5914C6.42733 11.5914 4.95285 10.1169 4.95285 8.29802C4.95285 6.47916 6.42733 5.00467 8.2462 5.00467C8.95591 5.00467 9.61319 5.22916 10.1509 5.61101Z"
                  fill="#EAD9C3"
                />
              </svg>
            }
            onClick={() => {
              if (client.primaryInvestmentGoal) {
                navigate(
                  `/clients/${client?._id}/explore/goal?goal=${
                    client.primaryInvestmentGoal === "retirementIncome"
                      ? "retirement-income"
                      : client.primaryInvestmentGoal === "retirementDrawdown"
                      ? "retirement-drawdown"
                      : "wealth-accumulation"
                  }`
                )
              } else {
                navigate(`/clients/${client._id}/explore/set-goal`)
              }
            }}
          />
        )}
        {theme.pages.profile.sidebar.menu.editClientDetails.showLink && (
          <SidebarMenuItem
            isDisabled={client.status === "Archived"}
            label={isExpanded ? "Edit client details" : ""}
            leftElement={
              <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.6791 7.1192L8.88243 5.32253L10.1811 4.0232L11.9771 5.8192L10.6791 7.1192ZM6.0531 11.7499L4.06843 11.9305L4.24443 9.95987L7.9891 6.2152L9.78643 8.01253L6.0531 11.7499ZM12.9358 4.89186L12.9351 4.8912L11.1098 3.06586C10.6158 2.5732 9.7671 2.54986 9.2991 3.01986L3.30177 9.0172C3.08443 9.23386 2.94977 9.52187 2.92177 9.82653L2.6691 12.6065C2.65177 12.8032 2.72177 12.9979 2.86177 13.1379C2.98777 13.2639 3.15777 13.3332 3.3331 13.3332C3.35377 13.3332 3.37377 13.3325 3.39377 13.3305L6.17377 13.0779C6.4791 13.0499 6.76643 12.9159 6.9831 12.6992L12.9811 6.7012C13.4664 6.21453 13.4458 5.40253 12.9358 4.89186Z"
                  fill="currentcolor"
                />
              </svg>
            }
            onClick={() => setIsEditModal(true)}
          />
        )}
        {theme.pages.profile.sidebar.menu.contact && (
          <SidebarMenuItem
            isDisabled={client.status === "Archived" || !email}
            label={isExpanded ? "Contact client" : ""}
            leftElement={
              <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.6668 12H3.3335C2.96616 12 2.66683 11.7013 2.66683 11.3333V4.83329L7.60016 8.53329C7.71883 8.62263 7.8595 8.66663 8.00016 8.66663C8.14083 8.66663 8.2815 8.62263 8.40016 8.53329L13.3335 4.83329V11.3333C13.3335 11.7013 13.0342 12 12.6668 12ZM12.2222 3.99996L8.00016 7.16663L3.77816 3.99996H12.2222ZM12.6668 2.66663H3.3335C2.23083 2.66663 1.3335 3.56396 1.3335 4.66663V11.3333C1.3335 12.436 2.23083 13.3333 3.3335 13.3333H12.6668C13.7695 13.3333 14.6668 12.436 14.6668 11.3333V4.66663C14.6668 3.56396 13.7695 2.66663 12.6668 2.66663Z"
                  fill="currentcolor"
                />
              </svg>
            }
            link={email ? `mailto:${email}` : "#"}
          />
        )}
        {theme.pages.profile.sidebar.menu.createHousehold && (
          <SidebarMenuItem
            isDisabled={client.status === "Archived" || !!client.externalId}
            label={isExpanded ? "Create household" : ""}
            leftElement={
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.6666 13.1667C14.6666 13.5347 14.3686 13.8333 14 13.8333C13.6313 13.8333 13.3333 13.5347 13.3333 13.1667C13.3333 12.064 12.436 11.1667 11.3333 11.1667C10.878 11.1667 10.4453 11.3227 10.096 11.5993C10.4586 12.2633 10.6666 13.0247 10.6666 13.8333C10.6666 14.2013 10.3686 14.5 9.99998 14.5C9.63131 14.5 9.33331 14.2013 9.33331 13.8333C9.33331 11.9953 7.83798 10.5 5.99998 10.5C4.16198 10.5 2.66665 11.9953 2.66665 13.8333C2.66665 14.2013 2.36865 14.5 1.99998 14.5C1.63131 14.5 1.33331 14.2013 1.33331 13.8333C1.33331 11.26 3.42731 9.16667 5.99998 9.16667C7.28465 9.16667 8.44865 9.68867 9.29331 10.5307C9.87265 10.0827 10.586 9.83333 11.3333 9.83333C13.1713 9.83333 14.6666 11.3287 14.6666 13.1667ZM11.3333 6.5C11.7013 6.5 12 6.79933 12 7.16667C12 7.534 11.7013 7.83333 11.3333 7.83333C10.9653 7.83333 10.6666 7.534 10.6666 7.16667C10.6666 6.79933 10.9653 6.5 11.3333 6.5ZM11.3333 9.16667C12.436 9.16667 13.3333 8.26933 13.3333 7.16667C13.3333 6.064 12.436 5.16667 11.3333 5.16667C10.2306 5.16667 9.33331 6.064 9.33331 7.16667C9.33331 8.26933 10.2306 9.16667 11.3333 9.16667ZM5.99998 3.83333C6.73531 3.83333 7.33331 4.43133 7.33331 5.16667C7.33331 5.902 6.73531 6.5 5.99998 6.5C5.26465 6.5 4.66665 5.902 4.66665 5.16667C4.66665 4.43133 5.26465 3.83333 5.99998 3.83333ZM5.99998 7.83333C7.47065 7.83333 8.66665 6.63733 8.66665 5.16667C8.66665 3.696 7.47065 2.5 5.99998 2.5C4.52931 2.5 3.33331 3.696 3.33331 5.16667C3.33331 6.63733 4.52931 7.83333 5.99998 7.83333Z"
                  fill="currentcolor"
                />
              </svg>
            }
            onClick={() => setIsMergeClientsModal(true)}
          />
        )}
      </SidebarMenu>

      {(isEditModal || isMergeClientsModal || showNeedsResultsModal) && (
        <AnimatePresence>
          {isEditModal && (
            <Modal handleClose={() => setIsEditModal(false)}>
              <EditProfileModal client={client} onClose={() => setIsEditModal(false)} />
            </Modal>
          )}
          {isMergeClientsModal && (
            <Modal handleClose={() => setIsMergeClientsModal(false)}>
              <MergeClientsModal client={client} onClose={() => setIsMergeClientsModal(false)} />
            </Modal>
          )}
          {showNeedsResultsModal && (
            <Modal className="max-w-xl" handleClose={() => setShowNeedsResultsModal(false)}>
              <NeedsResultsModal client={client} onClose={() => setShowNeedsResultsModal(false)} />
            </Modal>
          )}
        </AnimatePresence>
      )}
    </motion.div>
  )
}

export default ProfileOverview
