import clsx from "clsx"
import { AnimatePresence } from "framer-motion"
import { useContext, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import axiosInstance from "../../api/axiosInstance"
import refreshIcon from "../../assets/icons/refresh.svg"
import { ClientHouseholdCacheContext } from "../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../contexts/FirmContext"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { latest, supportedGames } from "../../lib/clients"
import { Client } from "../../models/Client"
import { Household } from "../../models/Household"
import EFPReprofileModal from "../../pages/clients/components/EFPReprofileModal"
import SendInvitesModal from "../../pages/clients/components/SendInvitesModal"
import AlertPopover, { AlertPopoverOptions } from "../AlertPopover/AlertPopover"
import Modal from "../Modal/Modal"
import ClientEFPStatusRow from "./ClientEFPStatusRow"
import ClientStatusAlert, { StatusAlert } from "./ClientStatusRow/ClientStatusAlert"
import { customDateFormat } from "../../lib/date"

const HouseholdEFPSection = ({ household }: { household: Household }) => {
  const { replace } = useContext(ClientHouseholdCacheContext)
  const { firm } = useContext(FirmContext)
  const trackViewEvent = useTrackViewEvent()
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const [sendInvitesModal, setSendInvitesModal] = useState<{ show: boolean; isReminder?: boolean }>()
  const [showReprofileModal, setShowReprofileModal] = useState(false)

  const { mutate: sendInvite, status: sendInviteUpdateState } = useMutation({
    mutationFn: ({ client, isReminder }: { client: Client; isReminder: boolean }) =>
      axiosInstance.put(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id.toString()}/efp/invite`, {}).then((res) => res.data as Client),
    onSuccess: (updated, { isReminder }) => {
      replace(updated)
      setInfoAlert({
        message: "Email has been sent",
        onClose: () => setInfoAlert(undefined),
        type: "success",
        location: isReminder ? "send_reminder" : "send_invite"
      })
    },
    onError: (err) => {
      console.error("Error sending invite", err)
    }
  })

  const { mutate: createEFP, status: createEFPUpdateState } = useMutation({
    mutationFn: ({ isReminder }: { isReminder: boolean }) =>
      Promise.all(
        household.members.map(({ client }) =>
          axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id.toString()}/efp`, {}).then((res) => res.data as Client)
        )
      ),
    onSuccess: (updatedClients, { isReminder }) => {
      updatedClients.forEach((updatedClient) => {
        replace(updatedClient)
        setInfoAlert({
          message: "Sending...",
          onClose: () => setInfoAlert(undefined),
          type: "activity",
          location: isReminder ? "send_reminder" : "send_invite"
        })
        sendInvite({ client: updatedClient, isReminder })
      })
    },
    onError: (err) => {
      console.error("Error confirming invites", err)
    }
  })

  const onConfirmSendInvites = ({ isReminder }: { isReminder: boolean }) => {
    setInfoAlert({
      message: "Sending invite...",
      timeout: false,
      type: "activity",
      location: "send_invite"
    })
    createEFP({ isReminder })
  }

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (!infoAlert) {
      return
    }
    return {
      content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
      onClose: infoAlert.onClose,
      timeoutMS: infoAlert.timeout === false ? null : 5000,
      location: infoAlert.location
    }
  }, [infoAlert])

  const anyActivityWithNoGame = useMemo(
    () => household.members.some(({ client }) => supportedGames(client, firm).some((gameType) => !latest(client, gameType))),
    [firm, household.members]
  )

  const anyToBeReprofiled = useMemo(
    () => household.members.some(({ client }) => supportedGames(client, firm).some((gameType) => latest(client, gameType)?.status === "Up for review")),
    [firm, household.members]
  )

  const anyGamePlayedByAllMembers = useMemo(
    () =>
      household.members.every(({ client }) =>
        supportedGames(client, firm).some((game) => latest(client, game)?.played && latest(client, game)?.status !== "Up for review")
      ),
    [firm, household.members]
  )

  const anyGamePlayedByAnyMember = useMemo(
    () =>
      household.members.some(({ client }) =>
        supportedGames(client, firm).some((game) => latest(client, game)?.played && latest(client, game)?.status !== "Up for review")
      ),
    [firm, household.members]
  )

  const anyActivityNotPlayed = useMemo(
    () =>
      household.members.some(({ client }) => supportedGames(client, firm).some((gameType) => latest(client, gameType) && !latest(client, gameType)!.played)),
    [firm, household.members]
  )

  const allIMDone = useMemo(
    () =>
      household.members.every(({ client }) =>
        supportedGames(client, firm).every((gameType) => latest(client, gameType) && latest(client, gameType)!.played && latest(client, gameType)!.summaryDate)
      ),
    [firm, household.members]
  )

  return (
    <div className="flex flex-col gap-x-3 gap-y-10">
      <div className="flex justify-between items-center">
        <div className="flex flex-col col-start-1 col-span-12 px-19">
          <h1 className="text-h2 text-main-600 font-semibold ">{`${household.name} household`}</h1>
          {allIMDone && household.nextActivityDue && <p className="text-sm">Re-profile due on {customDateFormat(household.nextActivityDue, "d MMM yyyy")}</p>}
        </div>
        {(sendInviteUpdateState === "error" || createEFPUpdateState === "error") && <div className="text-error">Error sending invites to the clients</div>}
        <div className="flex gap-x-5 pr-19">
          {anyGamePlayedByAllMembers && !allIMDone && (
            <Link
              className="btn btn-medium btn-primary !w-44 text-center animate-highlight-ping"
              to={`/households/${household._id}/rm/overview`}
              target="_blank"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                trackViewEvent({ action: "click", category: "present_results", label: household._id })
                window.open(`/households/${household._id}/rm/overview`)
              }}
            >
              Present results
            </Link>
          )}
          {(anyActivityWithNoGame || anyToBeReprofiled) && (
            <AlertPopover options={alertPopoverOptions} show={!!infoAlert}>
              <button
                className={clsx("btn btn-medium w-44", anyGamePlayedByAllMembers ? "btn-secondary" : "btn-primary")}
                onClick={() => setSendInvitesModal({ show: true })}
              >
                Send invites
              </button>
            </AlertPopover>
          )}
          {anyActivityNotPlayed && !(anyActivityWithNoGame || anyToBeReprofiled) && (
            <AlertPopover options={alertPopoverOptions} show={infoAlert?.location === "send_reminder"}>
              <button
                className={clsx("btn btn-medium w-44", anyGamePlayedByAllMembers ? "btn-secondary" : "btn-primary")}
                onClick={() => setSendInvitesModal({ show: true, isReminder: true })}
              >
                Send reminder
              </button>
            </AlertPopover>
          )}
          {anyGamePlayedByAnyMember && (
            <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={() => setShowReprofileModal(true)}>
              <img src={refreshIcon} alt="" aria-hidden />
              Re-profile
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-6">
        {household.members.map(({ client }) => (
          <ClientEFPStatusRow key={client._id} client={client} availableGames={supportedGames(client, firm)} />
        ))}
      </div>

      <AnimatePresence>
        {sendInvitesModal?.show && (
          <Modal className="modal-medium" contentClassName="!px-10 w-full" handleClose={() => setSendInvitesModal({ show: false })}>
            <SendInvitesModal
              onConfirm={() => {
                onConfirmSendInvites({ isReminder: sendInvitesModal.isReminder ?? false })
              }}
              onClose={() => setSendInvitesModal({ show: false })}
              household={household}
            />
          </Modal>
        )}
        {showReprofileModal && (
          <Modal contentClassName="!px-10 w-full" handleClose={() => setShowReprofileModal(false)}>
            <EFPReprofileModal onClose={() => setShowReprofileModal(false)} household={household} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default HouseholdEFPSection
