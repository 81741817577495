import { AnimatePresence } from "framer-motion"
import { useState } from "react"
import Modal from "../../../../../components/Modal/Modal"
import bar from "../../../../clients/reports/assets/Menu.svg"
import PatternsModal from "./PatternsModal"

const PatternsModalLink = ({ type }: { type: "risk" | "loss" }) => {
  const [patternsModal, setPatternsModal] = useState<boolean>(false)

  return (
    <div className="flex items-center pb-4">
      <img alt="menu" className="w-3 h-2" src={bar} />
      <button
        className="btn btn-text btn-text-xs ml-2 cursor-pointer text-interactive-500 border-0 text-left text-xs font-bold"
        onClick={() => {
          setPatternsModal(true)
        }}
      >
        Show example patterns
      </button>
      <AnimatePresence>
        {patternsModal && (
          <Modal handleClose={() => setPatternsModal(false)}>
            <PatternsModal type={type} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default PatternsModalLink
