import { Outlet, Route, Routes } from "react-router"
import RMJRiskAttitudePage from "./riskAttitude/RMJRiskAttitudePage"
import RMJYourDecisionsPage from "./yourDecisions/RMJYourDecisionsPage"
import RMJPortfolioComfortPage from "./portfolioComfort/RMJPortfolioComfortPage"
import RMJOverviewPage from "./overview/RMJOverviewPage"
import RMJAdviceOverviewÏPage from "./adviceOverview/RMJAdviceOverviewPage"
import RMJGoalExplorerPage from "./goalExplorer/RMJGoalExplorerPage"
import RMJLossSensitivityPage from "./lossSensitivity/RMJLossSensitivityPage"
import RMJSummaryPage from "./summary/RMJSummaryPage"

const RMJourney = () => (
  <div className="relative pg-ctr w-full h-full">
    <Outlet />
    <Routes>
      <Route path="overview" element={<RMJOverviewPage />} />
      <Route path="advice-overview" element={<RMJAdviceOverviewÏPage />} />
      <Route path="your-decisions" element={<RMJYourDecisionsPage />} />
      <Route path="risk-attitude" element={<RMJRiskAttitudePage />} />
      <Route path="loss-sensitivity" element={<RMJLossSensitivityPage />} />
      <Route path="portfolio-comfort" element={<RMJPortfolioComfortPage />} />
      <Route path="goal-explorer" element={<RMJGoalExplorerPage />} />
      <Route path="summary" element={<RMJSummaryPage />} />
    </Routes>
  </div>
)

export default RMJourney
