import { Trans } from "@lingui/macro"
import axios, { AxiosError } from "axios"
import clsx from "clsx"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import AlertIcon from "../../assets/icons/AlertIcon"
import infoAlert from "../../assets/icons/info-alert.svg"
import Checkbox from "../../components/Checkbox/Checkbox"
import Loading from "../../components/ClientProfile/Loading/Loading"
import { useValidPassword, useValidUsername } from "../../hooks/useAuthHooks"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { Password, Username } from "./AuthComponents"
import { AuthContext, SessionInfo } from "./AuthContext"
import InputCode from "../../components/InputCode/InputCode"
import { AnimatePresence, motion } from "framer-motion"
import Modal from "../../components/Modal/Modal"
import errorImage from "./images/plus-cross.svg"
import Dropdown from "../../components/Dropdown/Dropdown"
import iflo from "./images/iflo.svg"
import chevronDown from "../../layout/images/chevron-down.svg"

const providerIcons: { [key: string]: string } = {
  intelliflo: iflo
}

const SignIn: React.FunctionComponent<{}> = () => {
  const { username, setUsername, usernameIsValid } = useValidUsername("")
  const { password, setPassword, passwordIsValid } = useValidPassword("")
  const [error, setError] = useState("")
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const authContext = useContext(AuthContext)

  const [challengeModal, setChallengeModal] = useState<{ challenge: "email", token: string} | null>()
  const [otherExpanded, setOtherExpanded] = useState(false)

  const navigate = useNavigate()
  const trackViewEvent = useTrackViewEvent()
  const [userDeactivated, setUserDeactivated] = useState<boolean>()
  const { mutate: signIn, isLoading, isSuccess, data, isError, error: authError } = useMutation<SessionInfo & { challenge: "email", token: string }, AxiosError<any>, { email: string, password: string, otp?: string }>(
    ({ email, password }) => axios.post<SessionInfo & { challenge: "email", token: string }>(
      `${import.meta.env.VITE_APP_API_BASE}/api/auth/login`, {
        email, password
      }).then(res => res.data)
  )

  const respondToChallenge = useMutation<SessionInfo, AxiosError<any>, { otp: string, token: string }>(
    ({ otp, token }) => axios.post<SessionInfo>(
      `${import.meta.env.VITE_APP_API_BASE}/api/auth/login-2fa`, {
        otp, token
      }).then(res => res.data), {
        onSuccess: (data) => {
          authContext.signedIn(data!)
          if (location.pathname === "/signin") {
            navigate('/', { replace: true })
          }
        }
      }
  )
  const signInClicked = () => {
    trackViewEvent({ action: "login", category: "Authentication", label: "Login with password" })
    signIn({ email: username, password })
  }
  
  useEffect(() => {
    if (isSuccess) {
      console.log("AuthContext::signInWithEmail() SUCCESS")
      if (data?.challenge) {
        setChallengeModal(data)
      } else {
        authContext.signedIn(data!)
        if (location.pathname === "/signin") {
          navigate('/', { replace: true })
        }
      }
    } else if (isError) {
      if (authError.response?.data === 'Account locked or deleted') {
        setUserDeactivated(true)
      } else {
        setError(authError?.response?.data?.message ?? String(authError?.response?.data))
      }
    }
  }, [isSuccess, data, isError, authError, authContext, navigate])

  
  const isFormValid = useCallback(() => {
    if (username.length > 0 && password.length > 0) {
      setError("")
      return true
    } else {
      setError("Your email address or password is incorrect.")
      return false
    }
  }, [username, password])

  useEffect(() => {
    if (shouldValidate) {
      isFormValid()
    }
  }, [username, password, shouldValidate, isFormValid])


  const oauth2providers = useQuery(
    ["oauth2-providers"],
    () => import.meta.env.VITE_APP_LOGIN_OAUTH2_ENABLE === "true"
      ? axios.get<{ id: string, type: string, name?: string, action: string, params: { [key: string]: string } }[]>(
        `${import.meta.env.VITE_APP_API_BASE}/api/auth/oauth2/providers`
        ).then(res => res.data)
      : Promise.resolve([]),
    { retry: false }
  )


  const loginWithOtherButtonRef = useRef<HTMLButtonElement>(null)
  return (<>
    <div className="w-full flex flex-col items-center lg:min-w-0 max-w-[530px] lg:h-screen justify-center gap-y-8">
      <form className="w-full lg:px-18" onSubmit={e => {
        e.preventDefault(); e.stopPropagation();
        setShouldValidate(true)
        if(isFormValid()) signInClicked()
      }}>
        <fieldset className="w-full">
          <h1 className="lg:mt-0 pb-4 mt-14 lg:pt-12 text-h1 text-main-600 font-semibold">
            <Trans id="login-heading">{import.meta.env.VITE_APP_TITLE ?? "Adviser Portal"}</Trans>
          </h1>
          <legend className="sr-only">Login</legend>
          <div role="alert">
            {userDeactivated && (
              <div className="flex gap-x-2 max-w-75 bg-white border border-interactive-200 shadow-sm p-1 items-center text-sm mb-2">
                <AlertIcon alertType="warning" />
                <p>Sorry, your account has been de-activated. Please contact your firm admin.</p>
              </div>
            )}
          </div>
          <div className="pt-4 w-full">
            <div className="mt-3">
              <Username error={error} usernameIsValid={usernameIsValid} setUsername={(val) => setUsername(val.toLowerCase().trim())} username={username} />
            </div>
            <div className="mt-3">
              <Password error={error} label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
              {/* {isInNewPasswordChallenge && (
                <Password error={error} id="new-password" label="Set new password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
              )} */}
            </div>
            <label className="flex gap-3 mt-3.5">
              <Checkbox name="keepLoggedIn" />
              <span className="text-main-500 font-semibold text-p select-none">Keep me logged in</span>
            </label>

            <div role="alert">
              {error && (
                <div className={clsx("login-failed-message mt-4 h-6 flex items-center gap-2 text-error")}>
                  <img alt="" className="login-failed-error-icon" src={infoAlert} aria-hidden />
                  {error}
                </div>
              )}
            </div>

            <div className="mt-8 w-full flex flex-col gap-y-2">
              <div className="w-full flex items-center justify-between gap-x-4 font-medium">
                <button
                  type="submit"
                  className="login-submit btn btn-medium btn-primary w-full m-auto px-16 flex justify-center"
                  disabled={isLoading}
                >
                  {isLoading ? <Loading type="dots" /> : <>Login</>}
                </button>
              </div>

              {import.meta.env.VITE_APP_LOGIN_OAUTH2_ENABLE === "true" && !oauth2providers.isError && (
                <div className="w-full flex flex-col items-center justify-between font-medium">
                  <button
                    type="button"
                    className="btn btn-medium btn-secondary w-full m-auto px-16 flex justify-center gap-x-2.5"
                    onClick={() => setOtherExpanded(!otherExpanded)}
                    ref={loginWithOtherButtonRef}
                  >
                    <span>Login with another account</span>
                    <img className={clsx("transition-transform duration-100", otherExpanded && "rotate-180")} src={chevronDown} />
                  </button>
                  <AnimatePresence>
                    {otherExpanded && (
                      <div className="relative w-full">
                        <Dropdown overlayClassName="w-full py-2" trigger={loginWithOtherButtonRef} alignVertical="bottom" handleClose={() => setOtherExpanded(false)}>
                          <motion.div
                            initial={{ maxHeight: 0, opacity: 0 }}
                            animate={{ maxHeight: (oauth2providers.data?.length ?? 0) * 68, opacity: 1 }}
                            exit={{ maxHeight: 0, opacity: 0 }}
                            transition={{ duration: 0.1, ease: 'easeInOut' }}
                            className="w-full text-sec text-left overflow-hidden"
                          >
                            {oauth2providers.isLoading ? (
                              <></>
                            ) : (
                              oauth2providers.data?.map((provider, idx) => (
                                <form
                                  key={idx + ":" + provider.id}
                                  className="w-full bg-white "
                                  method="POST"
                                  action={provider.action}
                                >
                                  {Object.keys(provider.params).map(p => (
                                    <input key={p} type="hidden" name={p} value={provider.params[p]} />
                                  ))}
                                  <input type="hidden" name="state" value={JSON.stringify({
                                      protocol: window.location.protocol,
                                      pathname: location.pathname,
                                      hostname: window.location.hostname,
                                      search: location.search,
                                      hash: location.hash,
                                    })} />
                                  <button type="submit" className="w-full btn btn-secondary text-left flex gap-x-3 items-center justify-start px-3 py-0 h-12 border-0 font-sans font-normal">
                                    {providerIcons[provider.type] && <img src={providerIcons[provider.type]}/>}
                                    {provider.name || provider.type}
                                  </button>
                                </form>
                              )
                            )
                            )
                            }
                          </motion.div>
                        </Dropdown>
                      </div>
                    )}
                  </AnimatePresence>
                </div>
            )}

            </div>
            <div className="text-center pt-3">
              <Link to="?_m=forgotpassword" className="btn btn-text btn-text-md font-bold text-interactive-500">
                <Trans id="login-forgot-password">Forgot password?</Trans>
              </Link>
            </div>
          </div>
        </fieldset>
      </form>
      {/* <div className="w-full lg:px-18">
        <hr className="w-full border-t border-surface-300"/>
      </div> */}
      <div className="flex items-center gap-x-4 whitespace-nowrap text-sec">
        <div>New user?</div>
        <div className="btn btn-text text-interactive-500 font-bold cursor-pointer"
          onClick={() => navigate(location.pathname + "?_m=setupaccount")}
        >
          Set up account
        </div>
      </div>
    </div>
      <AnimatePresence>
        {challengeModal && (
          <Modal handleClose={() => setChallengeModal(null)}>
            <div className=" mx-auto my-auto">
              <div className="modal-container flex flex-col gap-6">
                <h1 className="modal-title text-left p-0">Enter code from email</h1>
                <p className="modal-description text-left p-0">
                  We have sent you an email with a 6-digit code. Please enter the code:
                </p>
                <div className="input-wrapper">
                  <InputCode length={6} onComplete={(otp) => respondToChallenge.mutate({ otp, token: challengeModal.token })} />
                </div>
                {respondToChallenge.isError && (
                  <div className="flex items-center gap-1 bg-white border border-neutral-300 p-2">
                    <img src={errorImage} alt="" aria-hidden />
                    <p className="text-sm">The verification code you entered is invalid. Please enter the correct code.</p>
                  </div>
                )}
                <p className="text-main-500 text-left">
                  If you didn't receive an email, please check your spam folder.
                </p>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default SignIn
