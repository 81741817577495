import clsx from "clsx"
import { AnimatePresence } from "framer-motion"
import { useContext, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import axiosInstance from "../../../api/axiosInstance"
import refreshIcon from "../../../assets/icons/refresh.svg"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import useTrackViewEvent from "../../../hooks/useTrackViewEvent"
import { latestGamesByType } from "../../../lib/clients"
import { customDateFormat } from "../../../lib/date"
import { Client, GameType } from "../../../models/Client"
import EFPReprofileModal from "../../../pages/clients/components/EFPReprofileModal"
import SendInvitesModal from "../../../pages/clients/components/SendInvitesModal"
import AlertPopover, { AlertPopoverOptions } from "../../AlertPopover/AlertPopover"
import Modal from "../../Modal/Modal"
import ClientEFPStatusRow from "../ClientEFPStatusRow"
import ClientStatusAlert, { StatusAlert } from "../ClientStatusRow/ClientStatusAlert"

const ClientEFPSection = ({ client, availableGames }: { client: Client; availableGames: GameType[] }) => {
  const { replace } = useContext(ClientHouseholdCacheContext)
  const trackViewEvent = useTrackViewEvent()
  const { firm } = useContext(FirmContext)
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const [sendInvitesModal, setSendInvitesModal] = useState<{ show: boolean; isReminder?: boolean }>()
  const [showReprofileModal, setShowReprofileModal] = useState(false)

  const { mutate: sendInvite, status: sendInviteUpdateState } = useMutation({
    mutationFn: ({ isReminder }: { isReminder: boolean }) =>
      axiosInstance.put(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id.toString()}/efp/invite`, {}).then((res) => res.data as Client),
    onSuccess: (updated, { isReminder }) => {
      replace(updated)
      setInfoAlert({
        message: "Email has been sent",
        onClose: () => setInfoAlert(undefined),
        type: "success",
        location: isReminder ? "send_reminder" : "send_invite"
      })
    },
    onError: (err) => {
      console.error("Error sending invite", err)
    }
  })

  const { mutate: createEFP, status: createEFPUpdateState } = useMutation({
    mutationFn: () => axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id}/efp`, {}).then((res) => res.data as Client),
    onSuccess: (updated) => {
      replace(updated)
      sendInvite({ isReminder: false })
    },
    onError: (err) => {
      console.error("Error creating efp", err)
    }
  })

  const onSendInvite = ({ isReminder }: { isReminder: boolean }) => {
    setInfoAlert({
      message: "Sending...",
      onClose: () => setInfoAlert(undefined),
      type: "activity",
      location: isReminder ? "send_reminder" : "send_invite"
    })
    sendInvite({ isReminder })
  }

  const onConfirmSendInvites = () => {
    setInfoAlert({
      message: "Sending invite...",
      timeout: false,
      type: "activity",
      location: "send_invite"
    })
    createEFP()
  }

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (!infoAlert) {
      return
    }
    return {
      content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
      onClose: infoAlert.onClose,
      timeoutMS: infoAlert.timeout === false ? null : 5000,
      location: infoAlert.location
    }
  }, [infoAlert])

  const gameByType = latestGamesByType(client, firm)

  const anyGamesNotCreated = useMemo(() => availableGames.some((game) => !gameByType[game]), [availableGames, gameByType])

  const anyNotPlayed = useMemo(() => availableGames.some((game) => gameByType[game] && !gameByType[game]?.played), [availableGames, gameByType])

  const anyGamePlayed = useMemo(
    () => availableGames.some((game) => gameByType[game] && gameByType[game]?.played && !gameByType[game]?.summaryDate),
    [availableGames, gameByType]
  )

  const allIMDone = useMemo(
    () => availableGames.every((game) => gameByType[game] && gameByType[game]?.played && gameByType[game]?.summaryDate),
    [availableGames, gameByType]
  )

  const anyNeedsToBeReprofiled = useMemo(
    () => availableGames.some((game) => gameByType[game] && gameByType[game]?.status === "Up for review"),
    [availableGames, gameByType]
  )

  return (
    <div className="flex flex-col gap-x-3 gap-y-10">
      <div className="flex justify-between items-center">
        <div className="flex flex-col col-start-1 col-span-12 px-19">
          <h1 className="text-h2 text-main-600 font-semibold ">{`${client.firstName} ${client.lastName}`}</h1>
          {allIMDone && client.nextActivityDue && <p className="text-sm">Re-profile due on {customDateFormat(client.nextActivityDue, "d MMM yyyy")}</p>}
        </div>
        {(sendInviteUpdateState === "error" || createEFPUpdateState === "error") && <div className="text-error">Error sending invite to the client</div>}
        <div className="flex gap-x-5 pr-19">
          {anyGamePlayed && !allIMDone && (
            <Link
              className="btn btn-medium btn-primary !w-44 text-center animate-highlight-ping"
              to={`/clients/${client._id}/rm/overview`}
              target="_blank"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                trackViewEvent({ action: "click", category: "present_results", label: client._id })
                window.open(`/clients/${client._id}/rm/overview`)
              }}
            >
              Present results
            </Link>
          )}
          {(anyGamesNotCreated || anyNeedsToBeReprofiled) && (
            <AlertPopover options={alertPopoverOptions} show={!!infoAlert}>
              <button className={clsx("btn btn-medium w-44", anyGamePlayed ? "btn-secondary" : "btn-primary")} onClick={() => setSendInvitesModal({ show: true })}>
                Send invite
              </button>
            </AlertPopover>
          )}
          {anyNotPlayed && (
            <AlertPopover options={alertPopoverOptions} show={infoAlert?.location === "send_reminder"}>
              <button
                className={clsx("btn btn-medium w-44", anyGamePlayed ? "btn-secondary" : "btn-primary")}
                onClick={() => setSendInvitesModal({ show: true, isReminder: true })}
              >
                Send reminder
              </button>
            </AlertPopover>
          )}
          {(anyGamePlayed || allIMDone) && (
            <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={() => setShowReprofileModal(true)}>
              <img src={refreshIcon} alt="" aria-hidden />
              Re-profile
            </button>
          )}
        </div>
      </div>
      <ClientEFPStatusRow client={client} availableGames={availableGames} />

      <AnimatePresence>
        {sendInvitesModal?.show && (
          <Modal className="modal-medium" contentClassName="!px-10 w-full" handleClose={() => setSendInvitesModal({ show: false })}>
            <SendInvitesModal
              onConfirm={() => {
                if (sendInvitesModal.isReminder) {
                  onSendInvite({ isReminder: true })
                } else {
                  onConfirmSendInvites()
                }
              }}
              onClose={() => setSendInvitesModal({ show: false })}
              client={client}
            />
          </Modal>
        )}
        {showReprofileModal && (
          <Modal contentClassName="!px-10 w-full overflow-y-scroll" className="overflow-scroll" handleClose={() => setShowReprofileModal(false)}>
            <EFPReprofileModal onClose={() => setShowReprofileModal(false)} client={client} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ClientEFPSection
