import { t } from "@lingui/macro"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { LegendData } from "../components/LineChart/LineChart"
import { createClientDataPoints } from "../utils/charts"
import { SENSITIVITY_TO_LOSS_DATA } from "./assets/sensitivityToLossData"
import { tt } from "../../../lib/translations"
import { Theme } from "../../../config/theme"
import { latest } from "../../../lib/clients"

export type LossSensitivityDetails =
  | {
      title: string
      description?: string
      reportNote?: string
      rmjNote?: string
    }
  | null
  | string

export const calculateLossAversionScore = (value: number | undefined) => {
  return value === undefined ? undefined : value <= 19 ? "low" : value <= 49 ? "mid" : "high"
}

export const lossSensitivityDetails = (client?: Client, household?: Household) => {
  if (client) {
    const lossAversion = latest(client, "risk")?.risk?.results?.R?.lossAversion
    const lossAversionLevel = calculateLossAversionScore(lossAversion)
    if (lossAversion === undefined || lossAversion === null) {
      return null
    } else {
      if (lossAversionLevel === "low") {
        return {
          title: t({ id: "sensitivity-to-loss-client-low-label", message: "You understand that markets can be volatile" }),
          description: t({
            id: "sensitivity-to-loss-client-low-description",
            message: "You understand that markets can be volatile"
          }),
          reportNote: null,
          rmjNote: lossSensitivityNoteContent(client)
        }
      } else if (lossAversionLevel === "mid") {
        return {
          title: t({ id: "sensitivity-to-loss-client-medium-label", message: "You may feel somewhat unsettled when markets are volatile" }),
          description: t({
            id: "sensitivity-to-loss-client-medium-description",
            message: "You may feel somewhat unsettled when markets are volatile"
          }),
          reportNote: t({
            id: "sensitivity-to-loss-report-note-individual-high",
            message: "We should discuss how best to navigate market volatility for the benefit of your long-term goals."
          }),
          rmjNote: lossSensitivityNoteContent(client)
        }
      } else if (lossAversionLevel === "high") {
        return {
          title: t({ id: "sensitivity-to-loss-client-high-label", message: "You may feel unsettled when markets are volatile" }),
          description: t({
            id: "sensitivity-to-loss-client-high-description",
            message: "You may feel unsettled when markets are volatile"
          }),
          reportNote: t({
            id: "sensitivity-to-loss-report-note-individual-high",
            message: "We should discuss how best to navigate market volatility for the benefit of your long-term goals."
          }),
          rmjNote: lossSensitivityNoteContent(client)
        }
      }
    }
  } else if (household) {
    const lossAversion1 = latest(household.members[0].client, "risk")?.risk?.results?.R?.lossAversion
    const lossAversion2 = latest(household.members[1].client, "risk")?.risk?.results?.R?.lossAversion

    if (lossAversion1 !== undefined && lossAversion1 !== null && lossAversion2 !== undefined && lossAversion2 !== null) {
      if (lossAversion1 <= 19 && lossAversion2 <= 19) {
        return { title: "You both understand that markets can be volatile" }
      } else {
        if (Math.abs(lossAversion1 - lossAversion2) > 10) {
          return { title: "You have different levels of loss sensitivity", rmjNote: lossSensitivityNoteContent(undefined, household) }
        } else {
          return { title: "You may both feel uncomfortable when markets are volatile", rmjNote: lossSensitivityNoteContent(undefined, household) }
        }
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export const lossSensitivityNoteContent = (client?: Client, household?: Household) => {
  let noteText
  if (client) {
    const game = latest(client, "risk")
    const individualLossAversion = calculateLossAversionScore(game?.risk.results?.R.lossAversion)
    if (individualLossAversion) {
      noteText = `What does a score of ${game?.risk.results?.R.lossAversion} mean? `
      noteText +=
        individualLossAversion === "low"
          ? t({
              id: "sensitivity-to-loss-note-individual-low",
              message: "While nobody likes short-term losses, your decisions suggest you’ll stay composed when markets are volatile."
            })
          : individualLossAversion === "mid"
          ? t({
              id: "sensitivity-to-loss-note-individual-mid",
              message:
                "Your decisions suggest that during periods of market volatility you may want to mitigate risk. We should discuss our plans for volatile times so we can best support you."
            })
          : individualLossAversion === "high"
          ? t({
              id: "sensitivity-to-loss-note-individual-high",
              message:
                "Your decisions suggest that during periods of market volatility you may want to de-risk. We should discuss our plans for volatile times so we can best support you."
            })
          : null
    }
  } else if (household) {
    const householdLossAversions = household?.members.map(({ client }) => calculateLossAversionScore(latest(client, "risk")?.risk.results?.R.lossAversion))
    if (householdLossAversions?.every((element) => element !== undefined) && [...new Set(householdLossAversions)].length === 1) {
      noteText =
        householdLossAversions[0] === "low"
          ? t({
              id: "sensitivity-to-loss-note-household-low",
              message: "While nobody likes short-term losses, your decisions suggest you’ll both stay composed when markets are volatile."
            })
          : householdLossAversions[0] === "mid"
          ? t({
              id: "sensitivity-to-loss-note-household-mid",
              message:
                "Your decisions suggest that during periods of market volatility you may both want to mitigate risk. We should discuss our plans for volatile times so we can best support you."
            })
          : householdLossAversions[0] === "high"
          ? t({
              id: "sensitivity-to-loss-note-household-high",
              message:
                "Your decisions suggest that during periods of market volatility you may both want to de-risk. We should discuss our plans for volatile times so we can best support you."
            })
          : null
    }
  }
  return noteText
}

export const lossSensitivityChartData = (theme: Theme, client?: Client, household?: Household) => {
  if (client || household) {
    const scores = client
      ? [latest(client, "risk")?.risk?.results?.R?.lossAversion]
      : household?.members.map(({ client }) => latest(client, "risk")?.risk?.results?.R?.lossAversion)
    const sensitivityScoreXYaxisValue = scores?.map((s) => SENSITIVITY_TO_LOSS_DATA.find((_, i) => i >= s!)!) ?? [] //SENSITIVITY_TO_LOSS_DATA.filter((xVal, i) => scores && scores?.indexOf(i) > -1)
    const xAxis = {
      labels: [
        { id: "1", text: "0" },
        { id: "2", text: "10" },
        { id: "3", text: "20" },
        { id: "4", text: "30" },
        { id: "5", text: "40" },
        { id: "6", text: "50" },
        { id: "7", text: "60" },
        { id: "8", text: "70" },
        { id: "9", text: "80" },
        { id: "10", text: "90" },
        { id: "11", text: "100" }
      ],
      title: tt({ id: "sensitivity-to-loss-x-axis-title", message: "Sensitivity to Loss score" })
    }
    const yAxis = {
      labels: [
        { id: "1", text: "0%" },
        { id: "2", text: "10%" },
        { id: "3", text: "20%" },
        { id: "4", text: "30%" },
        { id: "5", text: "40%" },
        { id: "6", text: "50%" }
      ],
      title: tt({ id: "sensitivity-to-loss-y-axis-title", message: "Percentage of investors" })
    }
    const highlightDataPoints = client
      ? createClientDataPoints({ clients: [client], theme: theme, values: sensitivityScoreXYaxisValue })
      : createClientDataPoints({ clients: household?.members.map(({ client }) => client), theme: theme, values: sensitivityScoreXYaxisValue })

    const legend: LegendData[] | undefined = client
      ? [
          {
            color: highlightDataPoints[0].color,
            id: `data-point-${client?.firstName}`,
            text: client?.firstName
          }
        ]
      : household?.members.map(({ client }, i) => {
          return {
            color: highlightDataPoints[i].color,
            id: `data-point-${client?.firstName}`,
            text: client?.firstName
          }
        })

    return {
      sensitivityToLossData: SENSITIVITY_TO_LOSS_DATA,
      sensitivityScoreXYaxisValue,
      highlightDataPoints,
      legend: legend ?? undefined,
      xAxis,
      yAxis
    }
  }
}
