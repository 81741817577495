import { ReactNode, useState } from "react"
import { motion } from "framer-motion"
import clsx from "clsx"

export interface Props {
  children: ReactNode
  isCollapsible?: boolean
  label?: string
}

const SidebarSection = ({ children, isCollapsible = false, label }: Props) => {
  const [isOpen, setIsOpen] = useState(!isCollapsible)

  return (
    <div
      className={clsx("sidebar-section-wrapper w-full border-b border-interactive-500", {
        "pt-5": !isCollapsible && !label
      })}
    >
      {isCollapsible && (
        <button className="group w-full flex items-center justify-between outline-none px-3 py-4" onClick={() => setIsOpen(!isOpen)}>
          <div className="group-focus-visible:shadow-focus group-hover:bg-interactive-500 w-full flex items-center justify-between px-2 py-1">
            <h3 className="text-white font-semibold">{label}</h3>
            <svg className={isOpen ? "rotate-0" : "rotate-180"} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.1999 9.99995C12.0467 9.99995 11.8929 9.94928 11.7661 9.84528L8.12565 6.86128L4.49128 9.73795C4.20051 9.96928 3.77349 9.92395 3.5383 9.63661C3.30378 9.34995 3.34987 8.93061 3.642 8.69928L7.70881 5.48061C7.95959 5.28128 8.3195 5.28395 8.56758 5.48795L12.6344 8.82128C12.9218 9.05661 12.9604 9.47728 12.7211 9.75995C12.5869 9.91795 12.3944 9.99995 12.1999 9.99995Z"
                fill="#F4E8D7"
              />
            </svg>
          </div>
        </button>
      )}
      {!isCollapsible && label && (
        <div className="w-full flex items-center justify-between p-5">
          <h3 className="text-white font-semibold">{label}</h3>
        </div>
      )}
      <motion.div
        animate={{ height: isOpen ? "auto" : 0, transition: { duration: 0.2, ease: "easeInOut" } }}
        className="w-full overflow-hidden"
        initial={false}
      >
        <div className="w-full px-5 pb-5">{children}</div>
      </motion.div>
    </div>
  )
}

export default SidebarSection
