import { useMemo, useRef, useState } from "react"
import AlertIcon from "../../../assets/icons/AlertIcon"
import { useTheme } from "../../../contexts/ThemeContext"
import useNumberFormatter from "../../../hooks/useNumberFormatter"
import { areDecisionsIncoherent, comfortMatch, finances } from "../../../lib/clients"
import { customDateFormat } from "../../../lib/date"
import { tt } from "../../../lib/translations"
import { Client, Game, GameType } from "../../../models/Client"
import { clientTopCauseByGame } from "../../../pages/clients/components/Profile/causeScores"
import ResultsAvailableMenu from "../../../pages/clients/components/Profile/components/ResultsAvailableMenu/ResultsAvailableMenu"
import RMJTextTooltip from "../../../pages/rmjourney/goalExplorer/components/RMJTextTooltip/RMJTextTooltip"
import presentation from "./presentation.svg"
import dots from "../../../assets/images/icons/dots-vertical-white.svg"
import clsx from "clsx"

const GameStatusCardHeader: React.FC<{ game?: Game; gameType: GameType; enabled: boolean }> = ({ game, gameType, enabled }) => {
  if (!enabled) {
    return "Activity not enabled"
  }
  if (game?.status === "Up for review") {
    return "Ready to re-profile"
  }
  if (!game) {
    return "Not invited"
  }
  if (!game.startedAt) {
    return "Activity not started yet"
  }
  if (!game.played) {
    return "Activity started"
  }
  if (!game.summaryDate) {
    return (
      <div className="flex gap-x-2">
        <p>Results ready to present</p>
        <img src={presentation} alt="" aria-hidden />
      </div>
    )
  }
  return (
    <p className="uppercase text-main-400 text-xs font-semibold tracking-extrawide">{tt({ id: `profile-page-status-card-${gameType}-up-to-date-title` })}</p>
  )
}

const GameStatusCardBody: React.FC<{ client: Client; game?: Game; enabled: boolean }> = ({ client, game, enabled }) => {
  const theme = useTheme()
  const numberFormatter = useNumberFormatter(theme.defaultLocale || "en-US")
  const clientFinances = finances(client)

  const retirementRiskMindset = useMemo(() => {
    if (game?.gameType === "retirement" && (game?.retirement?.decisions?.GI?.length ?? 0) > 0) {
      const averageChoiceX = Math.round(
        (game?.retirement?.decisions?.GI?.reduce((sum, item) => sum + item.choiceX!, 0) ?? 0) / (game?.retirement?.decisions?.GI?.length || 1)
      )
      return averageChoiceX <= 50 ? "high" : "low"
    }
  }, [game?.gameType, game?.retirement?.decisions?.GI])

  if (!enabled) {
    return "Enable in Activity settings to send invite"
  }
  if (game?.status === "Up for review") {
    return "Send invite to re-profile"
  }
  if (!game) {
    return "Send invite to complete activity"
  }
  if (!game.startedAt) {
    return `Invited on ${customDateFormat(game.sent ?? String(game.created))}`
  }
  if (!game.played) {
    return `Started on ${customDateFormat(game.startedAt)}`
  }
  if (!game.summaryDate) {
    return `Completed on ${customDateFormat(game.played)}`
  }
  return (
    <p className="font-bold text-sm">
      {game.gameType === "risk" && `${comfortMatch(game)?.portfolio?.name} investor`}
      {game.gameType === "esg" && `${clientTopCauseByGame(game, theme)?.title}`}
      {game.gameType === "goals" &&
        client.goals?.goalDetails
          ?.map((goal) => {
            if (goal.type === "createOwnGoal") {
              return goal.name
            }
            return tt({ id: `profile-page-status-card-goals-goal-${goal.type}-title` })
          })
          .join(", ")}
      {game.gameType === "pfs" &&
        `Net worth of $${numberFormatter.format(clientFinances.netWorth)}, surplus of ${numberFormatter.format(clientFinances.balance)}/${tt({
          id: `${clientFinances.baseFrequency}-option-postfix`
        })}`}
      {game.gameType === "tax" && "Tax-efficient portfolio"}
      {game.gameType === "retirement" && tt({ id: `retirement-results-persona-header-${retirementRiskMindset}` })}
      {game.gameType === "liquidity" && "Consider allocating 20% of your portfolio to alternative investments"}
    </p>
  )
}

const ClientGameStatusCard = ({ client, game, gameType, enabled }: { client: Client; game?: Game; gameType: GameType; enabled: boolean }) => {
  const theme = useTheme()
  const themeGame = theme.games.find((g) => g.type === gameType)
  const warningTooltipRef = useRef<HTMLDivElement>(null)
  const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false)
  const warningTooltipPos = warningTooltipRef.current?.getBoundingClientRect()
  const offSetX = 0
  const offSetY = -20

  return (
    <div className={clsx("relative flex flex-col shrink-0 rounded-1 shadow-100 pb-3 w-[226px]", { "opacity-30": !enabled })}>
      {game?.played && !game.summaryDate && (
        <div className="menu absolute right-0 top-0 py-2.5 px-3 z-10">
          <ResultsAvailableMenu client={client} game={game} icon={dots} />
        </div>
      )}
      <div className="flex gap-x-2 py-3 pr-3 pl-2.5 items-center h-15" style={{ backgroundColor: themeGame?.color }}>
        <img src={themeGame?.hero} alt="" />
        <p className="font-bold text-sec leading-4 text-white">{tt({ id: `game-${gameType}-title` })}</p>
      </div>
      <div className="flex flex-col gap-y-2 pt-4 pr-4 pl-3 h-[84px]">
        <div className="text-sm leading-2 font-bold">
          <GameStatusCardHeader game={game} gameType={gameType} enabled={enabled} />
        </div>
        <div className="text-sm leading-2">
          <GameStatusCardBody client={client} game={game} enabled={enabled} />
        </div>
        {areDecisionsIncoherent(game) && (
          <div ref={warningTooltipRef}>
            <div
              className="flex items-center justify-center cursor-pointer bg-red-200 px-2 py-1"
              onMouseOver={() => setShowWarningAlert(true)}
              onMouseOut={() => setShowWarningAlert(false)}
            >
              <AlertIcon sizeProp="sm" />
              <span className="text-xs text-red-600 font-bold tracking-extrawide pl-1 uppercase">Decision check</span>
            </div>
            {showWarningAlert && warningTooltipPos && (
              <div
                className="fixed w-[375px] cursor-default z-50"
                style={{
                  top: `${warningTooltipPos.top - offSetY}px`,
                  left: `${warningTooltipPos.left + warningTooltipPos.width! - (warningTooltipPos.width! / 2 - offSetX)}px`
                }}
              >
                <RMJTextTooltip>
                  <p className="text-sec leading-3">
                    This profile showed inconsistencies. Please re-profile this client to get a clear read of their preferences before you present their
                    results.
                  </p>
                </RMJTextTooltip>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ClientGameStatusCard
