import { Trans } from "@lingui/macro"
import clsx from "clsx"
import { motion } from "framer-motion"
import { latest } from "../../../../../lib/clients"
import { formatCurrencyShort } from "../../../../../lib/currency"
import { Client } from "../../../../../models/Client"
import { AVATAR_ICON_BG_CLASSES } from "../../../portfolioComfort/RMJPortfolioComfortPage"
import Opportunity from "../Opportunity/Opportunity"

interface Props {
  clients: Client[]
  enableAnimations?: boolean
  forReport?: boolean
  showNamesInFooter?: boolean
}

const DecisionsTable = ({ clients, enableAnimations = true, forReport = false, showNamesInFooter = true }: Props) => {
  return (
    <>
      <table
        className={clsx("w-full text-main-500", {
          "mx-2": !forReport
        })}
      >
        <thead>
          <tr className="decision-table-column-heading h-10 text-left text-main-600 font-semibold text-sec">
            <th className="pr-10 px-2">
              <Trans id="decision-table-column-heading-opportunity-text-1">Opportunity</Trans>
            </th>
            <th className={clsx("px-2", !forReport ? "w-4/5" : "w-2/3")}>% risked</th>
            <th className={clsx("w-full px-2", !forReport ? "text-center" : "text-left")}>Gain/Loss decisions</th>
          </tr>
        </thead>
        <tbody>
          {[4, 2, 0, 3, 1, 5]?.map((index, i, arr) => {
            return (
              <tr
                key={i}
                className={clsx("text-sec h-16 border-b border-b-surface-300", {
                  "border-b-0": i === arr.length - 1 && forReport
                })}
              >
                <td className="pr-10 text-left px-2">
                  <Opportunity index={i} />
                </td>
                <td className="text-sec text-center px-2">
                  <div className="flex flex-col justify-center h-full w-full gap-4">
                    {clients.map((client, j) => {
                      const game = latest(client, "risk")
                      const { choiceX } = game?.risk?.decisions?.R?.[index] ?? {}
                      const colorIndex = j % clients.length

                      return (
                        <div className="relative flex items-center pr-13 text-sec" key={`${client._id}-${j}`}>
                          <motion.div
                            initial={{ width: 0 }}
                            animate={{ width: `${choiceX === 0 ? 0.5 : choiceX}%` }}
                            transition={{ duration: enableAnimations ? 0.8 : 0, delay: enableAnimations ? 0.5 : 0, ease: "easeInOut" }}
                            className={clsx("decision-table-progress-bar relative h-1", {
                              "bg-avatar-0-500": colorIndex === 0,
                              "bg-avatar-1-500": colorIndex === 1
                            })}
                          >
                            <div className="decision-table-progress-bar-text absolute top-0 right-0 translate-x-full -translate-y-1/3 w-9 text-main-600 text-left -mr-2">
                              {Math.round(choiceX!)}%
                            </div>
                          </motion.div>
                        </div>
                      )
                    })}
                  </div>
                </td>
                <td className={clsx("decisions-table-gainloss-col px-2 flex items-center h-16", !forReport && "justify-center")}>
                  <div className="flex flex-col gap-0.5">
                    {clients.map((client, k) => {
                      const game = latest(client, "risk")
                      const { choiceGain, choiceLoss } = game?.risk?.decisions?.R?.[index] ?? {}
                      const colorIndex = k % clients.length

                      return (
                        <div className={clsx("grid grid-cols-12 text-sec", !forReport ? "w-40 pl-3 gap-x-2.5" : "w-28 gap-x-3")} key={`${client._id}-${k}`}>
                          <p
                            className={clsx("decision-table-gain-text col-span-6", {
                              "text-positive-600": colorIndex === 0 && clients.length === 1,
                              "text-avatar-0-600": colorIndex === 0 && clients.length !== 1,
                              "text-avatar-1-600": colorIndex === 1 && clients.length !== 1
                            })}
                          >{`+${formatCurrencyShort(choiceGain)?.toLocaleString()}`}</p>
                          <p
                            className={clsx("decision-table-loss-text col-start-7 col-span-6 text-left", {
                              "text-negative-600": colorIndex === 0 && clients.length === 1,
                              "text-avatar-0-600": colorIndex === 0 && clients.length !== 1,
                              "text-avatar-1-600": colorIndex === 1 && clients.length !== 1
                            })}
                          >{`-${formatCurrencyShort(choiceLoss)?.toLocaleString()}`}</p>
                        </div>
                      )
                    })}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {forReport && showNamesInFooter && (
        <div className="w-full flex justify-center items-center gap-6 text-sm mt-2">
          {clients.map((client, i) => (
            <div className="flex gap-2 items-center" key={i}>
              <div className={clsx("w-2 h-2 rounded-full", AVATAR_ICON_BG_CLASSES[i])} />
              <p className="text-main-400">{client.firstName}</p>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default DecisionsTable
