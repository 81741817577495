import { t } from "@lingui/macro"
import { Theme } from "../../../config/theme"
import { latest } from "../../../lib/clients"
import { tt } from "../../../lib/translations"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { LegendData } from "../components/LineChart/LineChart"
import { createClientDataPoints } from "../utils/charts"
import { ATTITUDE_TO_RISK_SCORES } from "./assets/attitudeToRiskData"

export type RiskAttitudeDetails =
  | {
      title: string
      description?: string
      note?: string
    }
  | null
  | string

export const riskAttitudeDetails = (client?: Client, household?: Household) => {
  if (client) {
    const game = latest(client, "risk")
    const riskTolerance = game?.risk?.results?.R?.riskTolerance
    if (riskTolerance === undefined || riskTolerance === null) {
      return null
    } else {
      if (riskTolerance <= 24) {
        return {
          title: t({ id: "risk-attitude-client-low-label", message: "You prefer lower risk investments" }),
          description: t({
            id: "risk-attitude-client-low-description",
            message: "Your decisions showed us you likely prefer a prudent approach to investment risk that delivers lower levels of risk and opportunity."
          }),
          note: t({
            id: "risk-attitude-client-low-note",
            message: "Among investors with wealth levels similar to yours, approximately 30% also prefer low amounts of risk."
          })
        }
      } else if (riskTolerance > 24 && riskTolerance <= 59) {
        return {
          title: t({ id: "risk-attitude-client-medium-label", message: "You're okay with some investment risk" }),
          description: t({
            id: "risk-attitude-client-medium-description",
            message: "Your decisions showed us that you’re still reasonably comfortable with taking risk when seeking investment opportunities."
          }),
          note: t({
            id: "risk-attitude-client-medium-note",
            message: "Among investors with wealth levels similar to yours, approximately 40% also take mid-level amounts of risk in pursuit of higher returns."
          })
        }
      } else if (riskTolerance > 59) {
        return {
          title: t({ id: "risk-attitude-client-high-label", message: "You're okay with investment risk" }),
          description: t({
            id: "risk-attitude-client-high-description",
            message: "Your decisions showed us that you’re still comfortable with taking risk when seeking investment opportunities."
          }),
          note: t({
            id: "risk-attitude-client-high-note",
            message: "Among investors with wealth levels similar to yours, approximately 30% are willing to take high amounts of risk in pursuit of higher returns."
          })
        }
      }
    }
  } else if (household) {
    const tolerances = household?.members?.map(member => {
      const game = latest(member.client, "risk")
      return game?.risk.results?.R.riskTolerance
    })
    if (tolerances.every(tolerance => tolerance !== undefined && tolerance !== null)) {
      if (Math.abs(Math.max(...tolerances) - Math.min(...tolerances)) > 10) {
        return { title: "Each of you is willing to tolerate different amounts of risk" }
      } else {
        return { title: "You are both willing to tolerate similar amounts of risk" }
      }
    } else {
      return null
    }
  }
}

export const riskAttitudeAxisValues = (theme: Theme, client?: Client, household?: Household) => {
  if (client || household) {
    const scores = client
      ? [latest(client, "risk")?.risk?.results?.R?.riskTolerance ?? 0]
      : household?.members.map(({ client }) => latest(client, "risk")?.risk?.results?.R?.riskTolerance ?? 0)
    const riskAttitudeScoreXYaxisValue = scores!.map((s) => ATTITUDE_TO_RISK_SCORES.find((_, i) => i >= s)!)
    const xAxis = {
      labels: [
        { id: "1", text: "0" },
        { id: "2", text: "10" },
        { id: "3", text: "20" },
        { id: "4", text: "30" },
        { id: "5", text: "40" },
        { id: "6", text: "50" },
        { id: "7", text: "60" },
        { id: "8", text: "70" },
        { id: "9", text: "80" },
        { id: "10", text: "90" },
        { id: "11", text: "100" }
      ],
      title: tt({ id: "risk-attitude-x-axis-title", message: "Attitude to Risk score" })
    }
    const yAxis = {
      labels: [
        { id: "1", text: "0%" },
        { id: "2", text: "5%" },
        { id: "3", text: "10%" },
        { id: "4", text: "15%" },
        { id: "5", text: "20%" },
        { id: "6", text: "25%" }
      ],
      title: tt({ id: "risk-attitude-y-axis-title", message: "Percentage of investors" })
    }
    const highlightDataPoints = client
      ? createClientDataPoints({ theme: theme, clients: [client], values: riskAttitudeScoreXYaxisValue })
      : createClientDataPoints({ theme: theme, clients: household?.members.map(({ client }) => client), values: riskAttitudeScoreXYaxisValue })

    const highlightAreas = [
      {
        endXPercent: 24,
        id: "low",
        label: "Low",
        startXPercent: 0
      },
      {
        endXPercent: 59,
        id: "mid",
        label: "Mid",
        startXPercent: 24
      },
      {
        endXPercent: 100,
        id: "high",
        label: "High",
        startXPercent: 59
      }
    ]

    const legend: LegendData[] | undefined = client
      ? [
          {
            color: highlightDataPoints[0].color,
            id: `data-point-${client?.firstName}`,
            text: client?.firstName
          }
        ]
      : household?.members.map(({ client }, i) => {
          return {
            color: highlightDataPoints[i].color,
            id: `data-point-${client?.firstName}`,
            text: client?.firstName
          }
        })

    return {
      attitudeToRiskScore: ATTITUDE_TO_RISK_SCORES,
      riskAttitudeScoreXYaxisValue,
      highlightAreas,
      highlightDataPoints,
      legend: legend ?? undefined,
      xAxis,
      yAxis
    }
  }
}
