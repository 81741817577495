import { useMemo } from "react"
import { useParams } from "react-router"
import peopleIcon from "../assets/images/icon-people.svg"
import bulb from "./images/bulb.svg"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import LineChart from "../components/LineChart/LineChart"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../../components/ClientProfile/LoadingError/LoadingError"
import { lossSensitivityChartData, lossSensitivityDetails } from "./lossSensitivityAttributes"
import PatternsModalLink from "../../clients/reports/RiskChartsPage/components/PatternsModalLink"
import { useTheme } from "../../../contexts/ThemeContext"
import { latest } from "../../../lib/clients"

const RMJLossSensitivityPage = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client, isClientLoading, clientLoadError } = useClient(clientId)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId)
  const theme = useTheme()
  const lossSensitivityMessages = lossSensitivityDetails(client, household)
  const chartData = useMemo(() => {
    return lossSensitivityChartData(theme, client, household)
  }, [theme, client, household])

  return (
    <div className="w-full h-full overflow-auto no-scrollbar">
      <div className="pg-ctr-py-lg h-full flex flex-col px-10 pb-0">
        <div className="flex justify-between px-6">
          <div>
            <p className="text-sec text-main-600 uppercase">Sensitivity to Loss</p>
            {lossSensitivityMessages?.title && <h4 className="text-h3 font-semibold text-main-600 pb-5">{lossSensitivityMessages.title}</h4>}
            {lossSensitivityMessages?.rmjNote && (
              <div className="flex bg-white border border-interactive-200 shadow-sm p-1 items-center text-sm mb-2">
                <img className="h-4 w-4" alt="" src={bulb} aria-hidden />
                <p className="ml-2 text-sm">{lossSensitivityMessages.rmjNote}</p>
              </div>
            )}
          </div>
          <div className="flex gap-8">
            {client ? (
              <h1 className="text-h2 text-right text-interactive-400 font-semibold flex flex-col">
                {latest(client, "risk")?.risk.results?.R.lossAversion}
                <span className="text-sm text-interactive-400">Sensitivity score</span>
              </h1>
            ) : (
              household?.members.map(({ client }, i) => (
                <h1 className="text-h2 text-right text-interactive-400 font-semibold flex flex-col items-end" key={client._id}>
                  <span className="flex items-center gap-2">
                    {chartData?.legend && <div className="w-2 h-2 rounded-full" style={{ backgroundColor: chartData.legend[i].color }} />}
                    {latest(client, "risk")?.risk.results?.R.lossAversion}
                  </span>
                  <span className="text-sm text-interactive-400">Sensitivity score</span>
                </h1>
              ))
            )}
          </div>
        </div>
        {(isClientLoading || isHouseholdLoading) && (
          <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
            <Loading />
          </div>
        )}
        <div className="rmj-sensitivity-loss-line-chart w-full min-h-[360px] grow px-6 pb-10 mt-10">
          {(client || household) && chartData && (
            <>
              <LineChart
                xAxis={chartData.xAxis}
                yAxis={chartData.yAxis}
                gameType={theme.gameType}
                icon={peopleIcon}
                type={"loss"}
                legend={chartData.legend}
                series={[
                  {
                    id: "1",
                    title: client?.firstName,
                    values: chartData.sensitivityToLossData,
                    color: "#7882D1"
                  }
                ]}
                highlightDataPoints={chartData.highlightDataPoints}
                highlightAreas={[
                  {
                    endXPercent: 19.5,
                    id: "low",
                    label: "Composed",
                    startXPercent: 0
                  },
                  {
                    endXPercent: 49.5,
                    id: "mid",
                    label: "Somewhat unsettled",
                    startXPercent: 19.5
                  },
                  {
                    endXPercent: 100,
                    id: "high",
                    label: "Unsettled",
                    startXPercent: 49.5
                  }
                ]}
                title="Your Sensitivity to Loss compared to other investors"
              />
              <PatternsModalLink type="loss" />
            </>
          )}
          {clientLoadError && <LoadingError message={"Could not load client"} />}
          {householdLoadError && <LoadingError message={"Could not load household"} />}
        </div>
      </div>
    </div>
  )
}

export default RMJLossSensitivityPage
