import { useMemo } from "react"
import { useParams } from "react-router"
import LineChart from "../components/LineChart/LineChart"
import peopleIcon from "../assets/images/icon-people.svg"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../../components/ClientProfile/LoadingError/LoadingError"
import { riskAttitudeAxisValues, riskAttitudeDetails } from "./riskAttitudeAttributes"
import PatternsModalLink from "../../clients/reports/RiskChartsPage/components/PatternsModalLink"
import { useTheme } from "../../../contexts/ThemeContext"
import { latest } from "../../../lib/clients"

const RMJRiskAttitudePage = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client, isClientLoading, clientLoadError } = useClient(clientId)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId)
  const theme = useTheme()

  const title = useMemo(() => {
    const riskAttitudeMessages = riskAttitudeDetails(client, household)
    return riskAttitudeMessages?.title ?? null
  }, [client, household])

  const attitudeToRisk = useMemo(() => {
    return riskAttitudeAxisValues(theme, client, household)
  }, [client, household, theme])

  return (
    <div className="w-full h-full overflow-auto no-scrollbar">
      <div className="pg-ctr-py-lg h-full flex flex-col px-10 pb-0">
        <div className="flex justify-between items-center px-6 mb-14">
          <div>
            <p className="text-sec text-main-600 mb-1 uppercase">Attitude to risk</p>
            {title && <h4 className="text-h3 font-semibold text-main-600">{title}</h4>}
          </div>
          <div className="flex gap-8">
            {client ? (
              <h1 className="text-h2 text-right text-interactive-400 font-semibold flex flex-col">
                {latest(client, "risk")?.risk.results?.R.riskTolerance}
                <span className="text-sm text-interactive-400">Risk score</span>
              </h1>
            ) : (
              household?.members.map(({ client }, i) => (
                <h1 className="text-h2 text-right text-interactive-400 font-semibold flex flex-col items-end" key={client._id}>
                  <span className="flex items-center gap-2">
                    {attitudeToRisk?.legend && <div className="w-2 h-2 rounded-full" style={{ backgroundColor: attitudeToRisk.legend[i].color }} />}
                    {latest(client, "risk")?.risk.results?.R.riskTolerance}
                  </span>
                  <span className="text-sm text-interactive-400">Risk score</span>
                </h1>
              ))
            )}
          </div>
        </div>
        {(isClientLoading || isHouseholdLoading) && (
          <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
            <Loading />
          </div>
        )}
        <div className="rmj-risk-attitude-line-chart w-full min-h-[360px] grow px-6 pb-10">
          {(client || household) && (
            <>
              <LineChart
                xAxis={attitudeToRisk!.xAxis}
                yAxis={attitudeToRisk!.yAxis}
                gameType="risk"
                icon={peopleIcon}
                type={"risk"}
                legend={attitudeToRisk!.legend}
                series={[
                  {
                    id: "1",
                    title: client?.firstName,
                    values: attitudeToRisk!.attitudeToRiskScore,
                    color: "#7882D1"
                  }
                ]}
                highlightAreas={attitudeToRisk!.highlightAreas}
                highlightDataPoints={attitudeToRisk!.highlightDataPoints}
                title="Your Attitude to Risk compared to other investors"
              />
              <PatternsModalLink type="risk" />
            </>
          )}
          {clientLoadError && <LoadingError message={"Could not load client"} />}
          {householdLoadError && <LoadingError message={"Could not load household"} />}
        </div>
      </div>
    </div>
  )
}

export default RMJRiskAttitudePage
